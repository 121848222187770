<template>
	<div>
		<div v-if="!selected_sizheng_content.content_id">
			<div style="font-size: 24px;color: #333; padding-left: 12px; border-left: 5px solid #333;line-height: 1;">
				课程思政实施方案
			</div>
		
			<div class="" style="width: 100%; display: inline-flex;  margin-top: 30px;" v-for="(item,index) in sizheng_week_list">
				<div class="" style="width: 96px; height: 96px;display: flex;flex-direction: column; justify-content: center;align-items: center; background-color: #d7d7d7;">
					<div class="" style="font-size: 20px; font-weight: 600;">
						第{{item.week_num}}周
					</div>
					<div class="" style="font-size: 16px;color: #333; margin-top: 5px;">
						<i class="el-icon-pie-chart" style="margin-right: -2px;"></i> {{item.class_hour}}课时
					</div>
				</div>
				<div class="" style="flex: 1; display: inline-flex; flex-direction: column;">
					<div style="width: 100%;min-height: 100%; display: inline-flex; background-color: #E6E6E6; padding:20px;font-size:20px;color: #333;font-weight: 600; line-height: 1.5;cursor: pointer;" v-html="item.content" @click="selectSiZhengWeek(item)" v-if="selected_sizheng_week.week_id !=item.week_id"></div>
					<div style="width: 100%;display: inline-flex;  flex-direction: column;background-color: #333; padding:20px; box-sizing: border-box; line-height: 1.5;cursor: pointer; " v-if="selected_sizheng_week.week_id ==item.week_id">
						<div v-for="(tem,indx) in sizheng_week_detail_list" @click="selectSiZhengContent(tem)" style="display: flex;flex-direction: column;font-size:20px;color: #fff; ">
							<div style="font-weight: 600; display: flex;">
								<div style="color: #fff; margin-right: 5px;">▪</div>
								<div>{{tem.content_title}}</div>
							</div>
							<div style="font-weight: normal;display: flex;flex-direction: column;margin: 12px 0;">
								<div v-for="(em,dx) in tem.point_list" style="display: flex; flex-direction: row; padding-left: 10px;">・ {{em.point_title}}</div>
							</div>
						</div>
		
					</div>
				</div>
			</div>
		</div>
		
		<div class="" v-if="selected_sizheng_content.content_id" style="width: 100%;display: flex;flex-direction: column; padding-bottom: 100px; position: relative;">
			<div style="position: absolute; right: 0; display: flex; align-items: center; cursor: pointer; " @click="clearSelected()">
				<i class="iconfont icon-back" style="margin-right: 5px; margin-bottom: -3px;font-size: 32px;"></i>
				<div style="font-size: 22px;">返回</div>
			</div>
			<div class="" style="width: 100%;font-size: 36px; font-weight: 600; color: #333;">
				{{sizheng_week_point_data.content_title}}
				
			</div>
			<div class="point_navs">
				<div class="point_nav" :class="{'point_nav_active': selected_sizheng_point.point_id == item.point_id}" v-for="(item,index) in sizheng_week_point_data.point_list" @click="selectSiZhengPoint(item)">知识点{{ transform(index) }}</div>
			</div>
			<div class="" style="display: flex;flex-direction: column;" v-for="(item,index) in sizheng_week_point_data.point_list" v-show="selected_sizheng_point.point_id == item.point_id">
		
				<div style="font-size: 20px;font-weight: 600; display: flex; color:#333; margin-top: 30px;">
					<div style="margin-right: 5px;">▪</div>
					<div style="line-height: 1.3;">{{replaceNum(item.point_title)}}</div>
				</div>
				<div class="" style="font-size: 20px;font-weight: 600;border-left: 5px solid #333; color: #333; padding-left: 8px; margin: 30px 0 15px;">
					教学方式：
				</div>
				<div class="" style="font-size: 20px;color: #333; line-height: 1.75;" v-html="replaceStr(item.method)"></div>
		
				<div class="" style="font-size: 20px;font-weight: 600;border-left: 5px solid #333; color: #333; padding-left: 8px; margin: 30px 0 15px;">
					思政什么：
				</div>
		
				<div class="" style="font-size: 20px;color: #333;line-height: 1.75;" v-html="replaceStr(item.for_what)"></div>
		
				<div class="" style="font-size: 20px;font-weight: 600;border-left: 5px solid #333; color: #333; padding-left: 8px; margin: 30px 0 15px;">
					如何思政：
				</div>
		
				<div class="" style="font-size: 20px;color: #333;line-height: 1.75;" v-html="replaceStr(item.how_to)"></div>
		
				<div class="" style="font-size: 20px;font-weight: 600;border-left: 5px solid #333; color: #333; padding-left: 8px; margin: 30px 0 15px;">
					深度思考：
				</div>
		
				<div class="" style="font-size: 20px;color: #333;line-height: 1.75;" v-html="replaceStr(item.deep_think)"></div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	
	export default {
		data() {
			return {
				sizheng_week_list: [],
				selected_sizheng_week: { week_id: '' },
				sizheng_week_detail_list: [],
				sizheng_week_point_data: {},
				selected_sizheng_content: {},
				selected_sizheng_point: {},
			};
		},
		created() {
			//document.title = "实施思政 - 数字教研室 - 机工教师特训营";
			this.getSiZhengWeekList();
		},
		methods: {
			clearSelected(){
				this.selected_sizheng_content ={};
			},
			replaceNum(str) {
				return str.replace(/1./g, '').replace(/2./g, '').replace(/3./g, '').replace(/4./g, '').replace(/5./g, '');
			},
			replaceStr(str) {
				if (!str) {
					return '无';
				} else {
					return str.replace(/；/g, '；<br>').replace(/？/g, '？<br>').replace(/：（/g, '：<br>（').replace(/<a/g, '<a target="_bank" class="link" ');
				}
			},
			transform(index) {
				var arr = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
				return arr[index];
			},
			getSiZhengWeekList() {
				var that = this;
				var url = apiUrl.sizheng_week_list_get;
				this.Ajax(url, { token: this.token }, (data) => {
					if (data) {
						that.sizheng_week_list = data;
					}
				});
			},
			selectSiZhengWeek(item) {
				if (this.selected_sizheng_week.week_id == item.week_id) {
					return;
				}
				this.selected_sizheng_week = item;
				console.log(JSON.stringify(item))
				console.log(this.selected_sizheng_content.content_id)
				this.getSiZhengWeekDetail();
			},
			getSiZhengWeekDetail() {
				var that = this;
				var url = apiUrl.sizheng_week_detail_get;
				this.Ajax(url, { token: this.token, week_id: this.selected_sizheng_week.week_id }, (data) => {
					if (data) {
						that.sizheng_week_detail_list = data;
						console.log(JSON.stringify(that.sizheng_week_detail_list))
					}
				});
			},
			selectSiZhengContent(item) {
				if (this.selected_sizheng_content.content_id == item.content_id) {
					return;
				}
				this.selected_sizheng_content = item;
				console.log(JSON.stringify(item))
				console.log(this.selected_sizheng_content.content_id)
				this.getSiZhengPointData();
			},
			getSiZhengPointData() {
				var that = this;
				var url = apiUrl.sizheng_point_detail_get;
				this.Ajax(url, { token: this.token, content_id: this.selected_sizheng_content.content_id }, (data) => {
					if (data) {
						that.sizheng_week_point_data = data;
						that.selected_sizheng_point = data.point_list[0];
					}
				});
			},
			selectSiZhengPoint(item) {
				this.selected_sizheng_point = item;
			},
		},
	};	
</script>

<style  lang="scss" scoped>
	
	.point_navs {
		width: 100%;
		display: flex;
		flex-direction: row;
		margin-top: 30px;
	
		.point_nav {
			font-size: 20px;
			color: #999;
			line-height: 1.75;
			margin-right: 40px;
			cursor: pointer;
	
			&.point_nav_active {
				font-weight: 600;
				color: #333;
				border-bottom: 1px solid #333;
			}
		}
	}
</style>