<template>
	<div style="display: flex;flex-direction: column;">
		<div class="" style=" display: flex; justify-content: space-between; align-items: flex-end;font-weight: 600;line-height: 1;">
			<div style="font-size: 24px;  border-left: 5px solid #333;color: #333;padding-left: 12px;">课程思政实施方案</div>
		</div>


		<div class="" style="width: 100%; margin: 25px 0; display: flex;flex-direction: column;">
			<div class="" style=" width: 100%; height: 449px; border: 0 solid #fff; background-color: #fff; box-sizing: border-box; position: relative; ">

				<div v-if="selected.res_type ==6 && selected.res_url" style="display:flex; width: 100%; height: 449px; z-index: 2; justify-content: center; align-items: center;">
					<iframe :src="selected.res_url" scrolling="auto" frameborder="0" width="100%" height="100%" align="center"></iframe>
					<div @click="fullscreenClick" style="position: absolute; width: 30px; height: 30px; border-radius: 50%; background-color: #2C2E30; bottom: 30px; right: 30px;display: flex; flex-direction: column;justify-content: center;align-items: center; cursor: pointer;">
						<i class="el-icon-full-screen" style="color: #ccc; font-weight: 600;"></i>
					</div>
				</div>

				<div v-if="show" class="ppt_chapters_scroll_y_box"
					style="z-index: 9; background-color: #eee; padding: 5px 15px; box-sizing: border-box ;box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);position: absolute;right: -10px; bottom: -15px; width: 316px; height: 360px; display: inline-flex;flex-direction: column; ">
					<div class="ppt_chapters_scroll_y"
						style=" width: 100%;  overflow-y: scroll; padding-right: 15px; box-sizing: border-box;">
						<div class="chapter-item" :class="{'actived':selected && selected.chapter_id == item.id}"
							v-for="(item,index) in chapterList" @click="changeChapter(item)"
							v-if="item.resourse_count > 0">
							<div class="title" v-if="item.title && item.title.split(' ')[0]">
								{{item.title.split(' ')[0]}}
							</div>
							<div class="sub_title" v-if="item.title && item.title.split(' ')[1]">
								{{item.title.split(' ')[1]}}
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="" style="width: 100%; height: 50px; background-color: #333;display: flex;">
				<div class="" v-if="selected && selected.title" style="flex: 1; display: flex;align-items: center; color: #fff; padding: 0 20px; box-sizing: border-box;">
					<i class="el-icon-document" style="font-size: 20px;margin-right: 5px;"></i> {{selected.title}}
				</div>
				<div @click="changeShow" class=""
					style="width: 140px; height: 100%; background-color: #CE671B; color: #fff; font-size: 20px; display: flex;justify-content: center;align-items: center;cursor:  pointer;">
					选择章节 <i class="iconfont icon-list" style="margin-left: 8px;font-size: 18px; "></i>

				</div>
			</div>
		</div>

		
		<!-- 全屏 -->
		<el-dialog v-if="zoomShow" :visible.sync="zoomShow" destroy-on-close :fullscreen="true">
			<!-- pdf浏览器 -->
			<div class="full-height-body" style=" display: block; width: 100%; height:100%;overflow: hidden; " v-if="selected.res_type ==6 && selected.res_url">
				<iframe :src="selected.res_url" scrolling="auto" frameborder="0" width="100%" height="100%" align="center"></iframe>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		props: {
			myid: String
		},
		data() {
			return {
				id: this.myid,
				search: {
					id: this.myid,
					page_index: 1,
					page_size: 3000,
				},
				chapterList: [],
				show: false,
				selected: null,
				zoomShow: '',
			};
		},
		created() {
			document.title = "课程思政实施方案 - 数字教研室 - 机工教师特训营";
			this.getChapters();
			var pdf_url='https://images.cmptt.com/h5/digital_teaching/commercial_bank/handout/fed8df3e2cc17d4a6d83018d8cbc5f2/pdf/sizheng/7544551b-d935-44ed-aea7-7d648ab2df23.pdf';
			this.selected = {
				title: 'PDF文件',
				res_url: `/pdf.js/4.7.76/web/viewer.html?file=${ encodeURIComponent(pdf_url) }`,
				res_type: 6
			};
		},
		methods: {
			fullscreenClick(){
				var that = this;
				
				that.$nextTick(() => {
					that.zoomShow = true;
				})
			},
			changeShow() {
				this.show = !this.show;
			},
			changeChapter(item) {
				this.show = false;
				this.getRes(item);
			},
			getChapters() {
				var that = this;
				var url = apiUrl.digital_office_chapter_list_get;
				this.Ajax(url, {
					id: this.id,
					menu_number: 3
				}, (data) => {
					that.chapterList = data;
					that.getRes(that.chapterList[0])
				});
			},
			getRes(item){
				var that = this;
				var url = apiUrl.digital_office_ext_detail_get;
				this.Ajax(url, {
					office_id: this.id,
					chapter_id: item.id,
				}, (data) => {
					if(data.res_type == 6){
						that.selected = {
							title: data.title,
							res_url: `/pdf.js/4.7.76/web/viewer.html?file=${ encodeURIComponent(data.res_url) }`,
							res_type: data.res_type,
							chapter_id: data.chapter_id
						};
					}
				});
			},
			
			
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-button--primary {
		background-color: #CE671B !important;
		border-color: #CE671B !important;
	}

	.ppt_chapters_scroll_y {
		padding-bottom: 100px !important;
	}

	.ppt_chapters_scroll_y::-webkit-scrollbar {
		width: 3px;
	}

	.ppt_chapters_scroll_y::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #3DD2BC;
		border-radius: 6px;
	}

	.chapter-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
		box-sizing: border-box;
		cursor: pointer;

		.title {
			font-weight: 600;
			color: #333;
		}

		.sub_title {
			margin-top: 5px;
			color: #999;
		}

		&:hover,
		&.actived {
			background-color: #E4E4E4;

			.title {
				color: #333;
			}

			.sub_title {
				color: #5d5d5d;
			}
		}

		&:last-child {
			border-bottom: 0;
		}
	}
</style>