<template>
	<div class="page-main" v-if="detailData">
		<!-- <div style="width: 100%;height: 400px;;display: flex; flex-direction: column; align-items: center;background: linear-gradient(to right,#EFF3DB,#28ACAD,#2BAB8D);"> -->
		<div style="width: 100%;height: 400px;;display: flex; flex-direction: column; align-items: center;">
			<div
				style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box;">
				<div class="mianb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">教材教辅</el-breadcrumb-item>
						<el-breadcrumb-item v-if="category_level1"
							:to="{ path: '/' }">{{category_level1.name}}</el-breadcrumb-item>
						<el-breadcrumb-item v-if="category_level2"
							:to="{ path: '/' }">{{category_level2.name}}</el-breadcrumb-item>
						<el-breadcrumb-item v-if="category_level3"
							:to="{ path: '/teachRes/list?num='+category_level3.num }">{{category_level3.name}}</el-breadcrumb-item>
						<el-breadcrumb-item v-if="detailData">{{detailData.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>

			<div id="top"
				style="flex: 1 ;display: flex; flex-direction: column;width: 1024px;max-width: 1024px; position: relative;">
				<img style="width: 230px; height: 322px; position: absolute; left: 50px; bottom: -5px; z-index: 10;box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee;"
					:src="detailData.image" />
				<div style="width: 100%; height: 100%; display: flex;flex-direction: row; justify-content: flex-end;">

					<div
						style="width: 700px;height: 100%;display:flex;flex-direction: column; line-height: 1.2; padding-top: 15px; ;box-sizing: border-box;">
						<div style="font-size: 30px; font-weight: 600;" v-html="detailData.title"></div>
						<div style="display: flex;flex-direction: column;font-size: 18px; margin-top: 15px;">
							<p style="margin-bottom: 8px;">作者：<span v-html="detailData.writer_name"></span></p>
							<p style="margin-bottom: 8px;">丛书名：<a
									@click="showSeriesList()">{{detailData.series_name}}</a></p>
							<p style="margin-bottom: 8px;">出版日期：<span>{{detailData.publishing_time}}</span></p>
							<p style="margin-bottom: 8px;">ISBN：<span>{{detailData.isbn}}</span></p>
							<p
								style="margin-bottom: 8px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">
								适用人群：<span v-html="detailData.target_users" v-if="detailData.target_users"></span><span
									v-if="!detailData.target_users">暂无</span></p>
						</div>
						<div
							style="display: flex;flex-direction: row;font-size: 20px; font-weight: 600; margin-top: 10px;">
							<div
								style="color: #00786c;  margin-right: 20px;display: flex;justify-content: center;align-items: center;">
								定价 ￥{{detailData.subscriber_price}}</div>
							<div
								style="display: inline-flex;justify-content: center;align-items: center;cursor: pointer;margin-right: 20px;">
								<div @click="showQrClick()" style="color: #333; display: flex;flex-direction: row; "
									v-if="detailData.uuid && detailData.series_id != 823">
									<i class="iconfont icon-shenqing" style="font-size: 24px; margin-right: 5px;"></i>
									样书申请
								</div>
								<el-tooltip effect="dark" content="本教材暂不提供教材样书" placement="top" v-if="!detailData.uuid || detailData.series_id == 823">
									<div style="color: #ccc; display: flex;flex-direction: row;">
										<i class="iconfont icon-shenqing"
											style="font-size: 24px; margin-right: 5px;"></i>
										样书申请
									</div>
								</el-tooltip>
							</div>

							<div
								style="display: inline-flex;justify-content: center;align-items: center;cursor: pointer">
								<div @click="readBook()" style="color: #333; display: flex;flex-direction: row; "
									v-if="detailData.uuid">
									<!-- <img style="width: 25px; height: 24px; margin-right: 6px;" src="../../assets/images/icon_book_zk_black.png" /> -->
									<i class="iconfont icon-weibiaoti--"
										style="font-size: 22px; margin-right: 5px;"></i>
									在线试读
								</div>
								<el-tooltip effect="dark" content="本教材暂不提供在线试读" placement="top" v-if="!detailData.uuid">
									<div style="color: #ccc; display: flex;flex-direction: row;">
										<i class="iconfont icon-weibiaoti--"
											style="font-size: 22px; margin-right: 5px;"></i>
										在线试读
									</div>
								</el-tooltip>
							</div>
						</div>

					</div>

				</div>
				<div
					style="width: 100%; height: 70px; display: flex; flex-direction: row; margin-bottom: -35px; z-index: 9;">
					<div
						style="flex: 1; height: 70px;background-color: #50DAC1; display: flex;flex-direction: row;justify-content: flex-end;">
						<div
							style="width: 500px; height: 70px; display: flex; flex-direction: row; font-size: 16px; align-items: center;">

							<div @click="gotoCourseDetail(detailData.course_id)" style=" margin-right: 30px;display: flex;justify-content: center;align-items: center; cursor: pointer; color: #00786c;"
								v-if="detailData.have_video_class == 1">
								<!-- <img style="width: 28px; height: 22px; margin-right: 10px;" src="../../assets/images/icon_video.png" /> -->
								<i class="iconfont icon-shipin" style="font-size: 26px;margin-right: 5px;"></i>
								视频课程
							</div>
							
							<el-tooltip effect="dark" content="本教材暂无相关课程" placement="bottom"
								v-if="detailData.have_video_class == 0">
								<div
									style="color: #80BAAE; display: flex;flex-direction: row; align-items: center;margin-right: 30px;">
									<i class="iconfont icon-shipin" style="font-size: 26px;margin-right: 8px;"></i>
									视频课程
								</div>
							</el-tooltip>

							<!-- 数字教研室 -->
							<div @click="gotoOfficeDetail(detailData.office_id)" style="margin-right: 30px;display: flex;justify-content: center;align-items: center; color: #00786c;cursor: pointer"
								v-if="detailData.have_digi_office == 1">
								<img style="width: 19px; height: 22px; margin-right: 8px;"
									src="../../assets/images/icon_room_green.png" />

								数字教研室
							</div>

							<el-tooltip effect="dark" content="本教材暂无相关数字教研室" placement="bottom"
								v-if="detailData.have_digi_office == 0">
								<div
									style="color: #80BAAE; display: flex;flex-direction: row; align-items: center;margin-right: 30px;">

									<img style="width: 19px; height: 22px; margin-right: 8px;"
										src="../../assets/images/icon_room.png" />
									数字教研室
								</div>
							</el-tooltip>

							<!-- 配书资源 -->
							<div @click="dialogResVisible = true"
								style="display: flex;justify-content: center;align-items: center; color:#00786c ;cursor: pointer"
								v-if="detailData.have_resourse == 1">
								<!-- <img style="width: 24px; height: 22px; margin-right: 10px;" src="../../assets/images/icon_yun.png" /> -->
								<i class="iconfont icon-yunxiazai" style="font-size: 22px; margin-right: 8px;"></i>
								教辅资源下载
							</div>
							<el-tooltip effect="dark" content="本教材暂无相关教辅资源" placement="bottom"
								v-if="detailData.have_resourse == 0">
								<div style="color: #80BAAE; display: flex;flex-direction: row; align-items: center;">
									<i class="iconfont icon-yunxiazai" style="font-size: 20px; margin-right: 8px;"></i>
									教辅资源下载
								</div>
							</el-tooltip>

						</div>
					</div>
					<div @click="dialogMsgVisible = true"
						style=" width: 200px;height: 70px;display:flex;background-color: #333; color:#fff ; justify-content: center;align-items: center; font-size: 22px;cursor: pointer">
						<img style="width: 36px; height: auto; margin-right: 10px;"
							src="../../assets/images/icon_kf.png" />
						教学支持
					</div>
				</div>

			</div>

		</div>
		<div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
			<div style="display: flex; flex-direction: row;width: 1024px;max-width: 1024px; margin: 60px 0;">
				<div class="sidebar">
					<ul>
						<li v-for="(item,index) in sideList" @click="targetClick(item)" @mouseover="sideHover(item)"
							@mouseout="sideHover()">
							<img v-if="selected && selected.id == item.id" class="hover_img"
								src="../../assets/images/icon_book_zk_white.png" />
							<img v-if="!selected || selected.id != item.id" class="default_img"
								src="../../assets/images/icon_book.png" />
							{{item.text}}
						</li>

					</ul>
				</div>

				<div class="main-content">

					<!-- 基础信息 -->
					<div class="info-box" id="exts">
						<div class="title">
							<i class="el-icon-caret-right"></i>扩展信息
						</div>
						<div class="info">
							<p>语种：<span>{{detailData.language}}</span></p>
							<p>页数：<span>{{detailData.papers}}</span></p>
							<p>开本：<span>{{detailData.folio}}</span></p>
							<p>原书名：<span>{{detailData.origin_book_name}}</span></p>
							<p>原出版社：<span>{{detailData.origin_publishing_house}}</span></p>
							<p>属性分类：<span>{{detailData.property_type}}</span></p>
							<p>包含CD：<span>{{detailData.contain_cd}}</span></p>
							<p>绝版：<span>{{detailData.be_out_of_print}}</span></p>
						</div>
					</div>

					<!-- 图书简介 -->
					<div class="info-box" id="description">
						<div class="title">
							<i class="el-icon-caret-right"></i>图书简介
						</div>
						<div class="info" v-html="detailData.description"></div>
					</div>

					<!-- 图书特色 -->
					<div class="info-box" id="characteristic" v-if="detailData.characteristic">
						<div class="title">
							<i class="el-icon-caret-right"></i>图书特色
						</div>
						<div class="info" v-html="detailData.characteristic"></div>
					</div>


					<!-- 图书前言 -->
					<div class="info-box" id="preface" v-if="detailData.preface">
						<div class="title">
							<i class="el-icon-caret-right"></i>图书前言
						</div>
						<div class="info part" v-html="detailData.preface"></div>
						<div class="readmore" @click="showMore('preface')">
							展开阅读
							<i class="el-icon-d-arrow-left"></i>
						</div>
						<div class="readback hide" @click="hideMore('preface')">
							收起
							<i class="el-icon-d-arrow-left"></i>
						</div>
					</div>


					<!-- 上架指导 -->
					<div class="info-box" id="grounding" v-if="detailData.grounding">
						<div class="title">
							<i class="el-icon-caret-right"></i>上架指导
						</div>
						<div class="info" v-html="detailData.grounding"></div>
					</div>


					<!-- 封底文字 -->
					<div class="info-box" id="hz_end" v-if="detailData.hz_end">
						<div class="title">
							<i class="el-icon-caret-right"></i>封底文字
						</div>
						<div class="info" v-html="detailData.hz_end"></div>
					</div>

					<!-- 图书目录 -->
					<div class="info-box" id="catelogs" v-if="detailData.catelogs">
						<div class="title">
							<i class="el-icon-caret-right"></i>图书目录
						</div>
						<div class="info part" v-html="detailData.catelogs"></div>
						<div class="readmore" @click="showMore('catelogs')">
							展开阅读
							<i class="el-icon-d-arrow-left"></i>
						</div>
						<div class="readback hide" @click="hideMore('catelogs')">
							收起
							<i class="el-icon-d-arrow-left"></i>
						</div>
					</div>


				</div>
			</div>
		</div>

		<!-- 配书资源弹窗 -->
		<el-dialog title="配书资源下载" :visible.sync="dialogResVisible" width="960" class="mydialog" :show-close="true">
			<div style=" width: 100%; padding: 20px; height: 400px;">
				<el-tabs v-model="activeName" type="card">
					<el-tab-pane :label="item.type_name" v-for="(item,index) in resTypeList">
						<div style="width: 100%; height: 320px; overflow-y: scroll;">
							<el-table :data="item.list" :show-header="false" style="width: 100%">
								<el-table-column label="名称">
									<template slot-scope="scope">
										<div style=" width: 100%; display: flex;align-items: center;">
											<i class="el-icon-upload"></i>
											<span style="margin-left: 10px">{{ scope.row.title }}</span>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="100">
									<template slot-scope="scope">
										<el-button size="mini" @click="downloadresClick(scope.row)">下载</el-button>

									</template>
								</el-table-column>
							</el-table>
						</div>

					</el-tab-pane>
				</el-tabs>
			</div>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button @click="dialogResVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogResVisible = false">确 定</el-button> -->

				<el-button type="primary" @click="dialogResVisible = false">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 教学支持弹窗 -->
		<el-dialog title="温馨提示" :visible.sync="dialogMsgVisible" width="960" class="mydialog" :show-close="true">
			<div class="layer_msg_box">
				<p class="layer_msg">高校教师有教学需求或问题，可根据教材类型（计算机或经管类）及所属区域联系下方客户经理。</p>
				<div class="layer_content_box">
					<div class="layer_left">
						<div class="layer_title">计算机教材联系人</div>
						<div class="layer_tr">
							<div class="layer_td1">四川、河南、山东、重庆、北京</div>
							<div class="layer_td2">丁浩</div>
							<div class="layer_td2">13810430562</div>
							<div class="layer_td2"><a @click="openPhoto('丁浩')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">陕西、湖北、湖南、江西、安徽</div>
							<div class="layer_td2">田学超</div>
							<div class="layer_td2">18986265960</div>
							<div class="layer_td2"><a @click="openPhoto('田学超')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">广东、江苏、广西、贵州、云南、海南</div>
							<div class="layer_td2">唐美春</div>
							<div class="layer_td2">13580340519</div>
							<div class="layer_td2"><a @click="openPhoto('唐美春')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">黑龙江、吉林、辽宁、福建、天津、新疆、河北、山西</div>
							<div class="layer_td2">何方</div>
							<div class="layer_td2">18909885898</div>
							<div class="layer_td2"><a @click="openPhoto('何方')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">上海、浙江、内蒙、甘肃、青海、宁夏</div>
							<div class="layer_td2">邱巍</div>
							<div class="layer_td2">13522991682</div>
							<div class="layer_td2"><a @click="openPhoto('邱巍')" class="layer_link">#微信#</a></div>
						</div>
						<!--
						 <div class="layer_tr">
							<div class="layer_td1">四川、河南、山东、重庆</div>
							<div class="layer_td2">丁浩</div>
							<div class="layer_td2">13810430562</div>
							<div class="layer_td2"><a @click="openPhoto('丁浩')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">北京、天津、山西、河北</div>
							<div class="layer_td2">余勇</div>
							<div class="layer_td2">13801271785</div>
							<div class="layer_td2"><a @click="openPhoto('余勇')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">湖北、湖南、江西、安徽</div>
							<div class="layer_td2">田学超</div>
							<div class="layer_td2">18986265960</div>
							<div class="layer_td2"><a @click="openPhoto('田学超')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">广东、江苏、广西、贵州、云南、海南</div>
							<div class="layer_td2">唐美春</div>
							<div class="layer_td2">13580340519</div>
							<div class="layer_td2"><a @click="openPhoto('唐美春')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">陕西、上海、甘肃、青海、宁夏、新疆、内蒙</div>
							<div class="layer_td2">张玖龙</div>
							<div class="layer_td2">15801446496</div>
							<div class="layer_td2"><a @click="openPhoto('张玖龙')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">黑龙江、吉林、辽宁、浙江、福建</div>
							<div class="layer_td2">何方</div>
							<div class="layer_td2">13691087080</div>
							<div class="layer_td2"><a @click="openPhoto('何方')" class="layer_link">#微信#</a></div>
						</div> 
						-->
					</div>
					<div class="layer_right">
						<div class="layer_title">经管教材联系人</div>
						<div class="layer_tr">
							<div class="layer_td1">四川、重庆、云南、贵州、河北、天津、湖北、湖南、江西、广东、广西、福建、海南</div>
							<div class="layer_td2">石洋</div>
							<div class="layer_td2">13801058919</div>
							<div class="layer_td2"><a @click="openPhoto('石洋')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">北京、陕西、甘肃、青海、宁夏、新疆、黑龙江、吉林、辽宁、内蒙古</div>
							<div class="layer_td2">赵杰锋</div>
							<div class="layer_td2">13811652732</div>
							<div class="layer_td2"><a @click="openPhoto('赵杰锋')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">山东、山西、浙江、河南、安徽、江苏、上海</div>
							<div class="layer_td2">焦少威</div>
							<div class="layer_td2">15188354302</div>
							<div class="layer_td2"><a @click="openPhoto('焦少威')" class="layer_link">#微信#</a></div>
						</div>
						
						<!-- 
						<div class="layer_tr">
							<div class="layer_td1">北京、陕西、甘肃、青海、宁夏、新疆</div>
							<div class="layer_td2">赵杰锋</div>
							<div class="layer_td2">13811652732</div>
							<div class="layer_td2"><a @click="openPhoto('赵杰锋')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">上海、辽宁、吉林、黑龙江、内蒙古</div>
							<div class="layer_td2">邱巍</div>
							<div class="layer_td2">13522991682</div>
							<div class="layer_td2"><a @click="openPhoto('邱巍')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">山东、山西、浙江</div>
							<div class="layer_td2">焦少威</div>
							<div class="layer_td2">15188354302</div>
							<div class="layer_td2"><a @click="openPhoto('焦少威')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">江苏、安徽、河南</div>
							<div class="layer_td2">刘斌</div>
							<div class="layer_td2">13810817150</div>
							<div class="layer_td2"><a @click="openPhoto('刘斌')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">湖北、湖南、江西</div>
							<div class="layer_td2">郭彪</div>
							<div class="layer_td2">13701144021</div>
							<div class="layer_td2"><a @click="openPhoto('郭彪')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">四川、重庆、云南、贵州、天津、河北</div>
							<div class="layer_td2">石洋</div>
							<div class="layer_td2">13801058919</div>
							<div class="layer_td2"><a @click="openPhoto('石洋')" class="layer_link">#微信#</a></div>
						</div>
						<div class="layer_tr">
							<div class="layer_td1">广东、广西、福建、海南</div>
							<div class="layer_td2">安伟</div>
							<div class="layer_td2">13811974518</div>
							<div class="layer_td2"><a @click="openPhoto('安伟')" class="layer_link">#微信#</a></div>
						</div> 
						-->
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button @click="dialogMsgVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogMsgVisible = false">确 定</el-button> -->

				<el-button type="primary" @click="dialogMsgVisible = false">确 定</el-button>
			</div>
		</el-dialog>



		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgList"></el-image-viewer>

		<!-- 购买提示 -->

		<el-dialog title="样书申请" width="420px" top="28vh" :visible.sync="dialogQrVisible" :show-close="true">
			<div
				style=" width: 100%; height: 150px; display: flex; flex-direction: column;justify-content: center;align-items: center;">
				<div ref="qrcode" style="width: 150px; height: 150px;"></div>
				<div style="margin-top: 10px;">
					请扫码二维码申请教材样书
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogQrVisible = false">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import QRCode from "qrcodejs2";

	export default {
		data() {
			return {
				id: null,
				detailData: null,
				selected: null,
				sideList: [{
						id: 'exts',
						text: '扩展信息',
						show: true
					},
					{
						id: 'description',
						text: '图书简介',
						show: this.detailData && this.detailData.description
					},
					{
						id: 'characteristic',
						text: '图书特色',
						show: this.detailData && this.detailData.characteristic
					},
					{
						id: 'preface',
						text: '图书前言',
						show: this.detailData && this.detailData.preface
					},
					{
						id: 'grounding',
						text: '上架指导',
						show: this.detailData && this.detailData.grounding
					},
					{
						id: 'hz_end',
						text: '封底文字',
						show: this.detailData && this.detailData.hz_end
					},
					{
						id: 'catelogs',
						text: '图书目录',
						show: this.detailData && this.detailData.catelogs
					},
					{
						id: 'top',
						text: '回到顶部',
						show: true
					},
				],
				category_level1: null,
				category_level2: null,
				category_level3: null,
				dialogMsgVisible: false,
				showViewer: false,
				imgList: [],
				resTypeList: [],
				activeName: '',
				dialogResVisible: false,
				isLogin: false,
				qrcode: null,
				dialogQrVisible: false,
			};
		},
		components: {
			ElImageViewer,
			qrcode: QRCode
		},
		created() {
			document.title = "机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			console.log(this.isLogin);


			var option = {
				id: this.$route.query.id || "",
			};
			if (option.id) {
				this.id = option.id;
				this.getDetail();

			}
		},
		methods: {
			gotoCourseDetail(id) {
				var url = "/course/detail";
				var query = { id: id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			gotoOfficeDetail(id){
				if(id !='9ea73161ee334b79a2ef0575316a088b'){
					this.$alert('该数字教研室正在准备中，敬请期待...', '提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				var url = "/teachRoom/detail";
				var query = { id: id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			showQrClick() {

				var that = this;
				that.dialogQrVisible = true;
				if (!that.qrcode) {
					that.$nextTick(() => {
						that.setupQrCode(that.$refs.qrcode, 'https://m.hzmedia.com.cn/edu/2019_index.aspx', 150, (
							code_img) => {
							that.qrcode = code_img;

						});
					});
				}
			},
			showSeriesList() {
				var url = "/teachRes/serieslist";
				var query = {
					id: this.detailData.series_id,
					name: this.detailData.series_name
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			downloadresClick(item) {

				if (item.ref && item.ref != '') {
					var newTab = window.open('about:blank');
					newTab.location.href = item.ref;
				} else {
					if (this.isLogin) {

						this.$confirm('本资源仅对教师开放，您尚未认证教师权限，是否认证教师权限', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							//window.top.location.href = "/teacherReg";
							this.$router.push({ path: '/teacherReg' });
						}).catch(() => {
							// this.$message({
							// 	type: 'info',
							// 	message: '已取消'
							// });
						});

						/*
						this.$alert('您暂无权限下载', '提示', {
							confirmButtonText: '确定'
						});
						*/
					} else {
						this.$confirm('您尚未登录, 是否登录?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							//window.top.location.href = "/login";
							this.$router.push({ path: '/login' });
						}).catch(() => {
							// this.$message({
							// 	type: 'info',
							// 	message: '已取消'
							// });
						});
					}

				}

			},
			resTypeTabClick(tab, event) {
				console.log(tab, event);
			},
			openPhoto(name) {
				var timestamp = Date.parse(new Date());
				var src = 'http://cloud.cmpreading.com/edu/images/wx/' + name + '.png?v=' + timestamp;
				var arr = new Array();
				arr.push(src);
				this.imgList = arr;
				this.showViewer = true;
			},
			closeViewer() {
				this.showViewer = false;
			},
			readBook() {
				var url = "/teachRes/reader";
				var query = {
					uuid: this.detailData.uuid,
					chapterid: this.detailData.chapterid
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			canNotRead() {
				this.$message({
					message: '本教材暂不提供在线试读',
					type: 'warning',
					offset: 500,
					duration: 1500
				});
			},
			getDetail() {
				var that = this;
				var url = apiUrl.product_detail_get;
				this.Ajax(url, {
					id: this.id
				}, (data) => {
					that.detailData = data.product_detail;
					document.title = that.detailData.title + " - 机工教师特训营";
					data.category_list.forEach((item, index) => {
						if (item.level == 1) {
							that.category_level1 = item;
						} else if (item.level == 2) {
							that.category_level2 = item;
						} else if (item.level == 3) {
							that.category_level3 = item;
						}
					});
					if (data.product_detail && data.product_detail.have_resourse == 1) {
						that.getResTypeList();
					}
				});
			},
			targetClick(item) {
				this.$el.querySelector('#' + item.id).scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})
			},
			showMore(id) {
				this.$el.querySelector('#' + id + '>.info').classList.remove('part');
				this.$el.querySelector('#' + id + '>.readmore').classList.add('hide');
				this.$el.querySelector('#' + id + '>.readback').classList.remove('hide');
			},
			hideMore(id) {
				this.$el.querySelector('#' + id + '>.info').classList.add('part');
				this.$el.querySelector('#' + id + '>.readmore').classList.remove('hide');
				this.$el.querySelector('#' + id + '>.readback').classList.add('hide');
			},
			sideHover(item) {
				this.selected = item;
			},
			//配书资料类型获取
			getResTypeList() {
				var that = this;
				var url = apiUrl.course_teach_resources_type_list_get;
				this.Ajax(url, {
					prep_id: this.id
				}, (data) => {
					that.resTypeList = data;
					that.resTypeList.forEach((item, index) => {
						that.getResList(item.type_id, (res) => {
							item.list = res;
						})
					})
				});
			},
			//根据配书资源类型获取资源列表
			getResList(g_type_id, callback) {
				var that = this;
				var url = apiUrl.course_teach_resources_list_get;
				this.Ajax(url, {
					prep_id: this.id,
					type_id: g_type_id
				}, (data) => {
					if (callback) callback(data);
				});
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},
		},
	};
</script>

<style>
	div,
	p,
	span {
		-webkit-user-select: text !important;
	}

	.el-image-viewer__wrapper {
		z-index: 3000 !important;
	}

	.mydialog .el-dialog__body {
		padding: 0;
	}

	div.layer_msg_box {
		padding: 8px 15px;
		line-height: 1.78;
		font-size: 14px;
	}

	p.layer_msg {
		border-bottom: 1px dotted #888888;
		line-height: 2.5;
	}

	div.layer_title {
		font-size: 13px;
		line-height: 2.5;
		color: #333333;
		font-weight: 600;
		clear: both;
		margin-bottom: -5px;
	}

	div.layer_tr {
		display: inline-block;
		font-size: 12px;
		line-height: 1.78;
		border-bottom: 1px dotted #ccc;
		padding: 8px 10px;
		box-sizing: border-box;
		color: #666666;
		display: flex;
		align-items: center;
	}

	div.layer_td1 {
		width: 180px;
		float: left;
	}

	div.layer_td2 {
		width: 100px;
		text-align: center;
		float: left;
	}

	a.layer_link {
		color: #358cf1;
	}

	div.layer_content_box {
		display: inline-block;
		width: 100%;
	}

	div.layer_left {
		width: 430px;
		float: left;
		overflow: hidden;
	}

	div.layer_right {
		width: 430px;
		margin-left: 35px;
		float: left;
		overflow: hidden;
	}

	div.layer_img_box {
		text-align: center;
	}

	div.layer_img_box img {
		margin: 0 auto;
	}
</style>
<style lang="scss">
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.sidebar {
			width: 150px;
			display: flex;
			flex-direction: column;


			ul {
				width: 100%;
				list-style: none;
				display: flex;
				flex-direction: column;
				border: 1px solid #eee;
				border-radius: 0 0 0 20px;

				li {
					height: 60px;
					border-bottom: 1px solid #eee;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #999999;
					background-color: #fff;
					cursor: pointer;

					&:last-child {
						border-bottom: none;
						border-radius: 0 0 0 20px;
					}

					&:hover {
						background-color: #333;
						color: #fff;
					}

					.default_img,
					.hover_img {
						width: 23px;
						height: 24px;
						margin-right: 10px;
					}
				}



			}
		}

		.main-content {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 150px;
			min-height: 80vh;
			background-color: #ececec;
			padding: 20px 40px;
			box-sizing: border-box;

			.info-box {
				display: flex;
				flex-direction: column;

				.title {
					font-size: 20px;
					color: #00786c;
					border-bottom: 1px solid #00786c;
					line-height: 2;

					i {
						margin: 0 5px 0 -5px;
					}
				}

				.info {
					font-size: 16px;
					color: #333;
					line-height: 2;
					margin: 15px 0 20px 0;

					&.part {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 16;
						-webkit-box-orient: vertical;
					}
				}

				.readmore {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 20px;
					color: #00786c;

					&.hide {
						display: none;
					}


					i {
						transform: rotate(-90deg);
						margin-top: 2px;
					}
				}

				.readback {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 20px;
					color: #00786c;

					&.hide {
						display: none;
					}

					i {
						transform: rotate(90deg);
						margin-top: 2px;
					}
				}
			}
		}



		.mianb {
			width: 100%;
			height: 75px;
			margin: 0 auto;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 18px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #363636;


			.el-breadcrumb {
				font-size: 16px;
			}

			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link,
			.el-breadcrumb__separator {
				color: #00786c !important;

			}

			.el-breadcrumb__inner.is-link:hover {
				border-bottom: 1px solid #00786c;
			}

			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #00786c !important;
			}

		}
	}
</style>