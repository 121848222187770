<template>
	<div class="page-main">
		<div class="body-main">

			<!-- 教师特训营 -->
			<div style="width: 100%; display: flex; flex-direction: column;">
				<div>

					<el-carousel :interval="5000" arrow="hover" :height="screenWidth">
						<el-carousel-item v-for="(item,index) in banner" v-if="(banner.length>4 && index<banner.length-2) || (banner.length<5 && index > -1)" >
							<img @click="toCampDetail(item)" style="cursor: pointer;" :src='item.img_url' width="100%" height="100%" />
						</el-carousel-item>
					</el-carousel>

				</div>

				<div style="width: 100%; display: flex; justify-content: space-between;">
				
					<div v-for="(item,index) in banner" v-if="index == banner.length-2 || index == banner.length-1"  @click="toCampDetail(item)" style="display: inline-flex; flex: 1; margin-top: 10px;position: relative;cursor: pointer;" :class="{'margin_right_10':index ==banner.length-2}">
						<img :src='item.img_url_index_other' style="width: 100%;" />
						<div style="width: 52px; height: 58px; display: inline-flex; flex-direction: column; justify-content: center;align-items: center; background-color:#F6892E ; color: #fff; font-weight: 600;  box-sizing: border-box; position: absolute; top: 0; right: 36px;">
							<div style="font-size: 22px; border-bottom: 2px solid #fff;">{{item.month}}月</div>
							<div style="font-size: 15px;">{{item.year}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 课程 -->
			<div
				style="display: flex; width: 100%;position: relative; display: flex; flex-direction: column; background-color: #fff; justify-content: center; align-items: center;position: relative;  overflow: hidden;">
				<img src="../../assets/images/bg_index_course_01.png"
					style="width: 100%;position: absolute; top: 0; z-index: 2;" />
				<div style="display: inline-flex; flex-direction: column; width: 1200px;z-index: 3; ">
					<div style="display: flex;  justify-content: space-between; margin: 45px 0 35px 0;">
						<div style="display: flex;">
							<div class=""
								style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">

							</div>
							<div style="display: flex;flex-direction: column; line-height: 1;">
								<div style="font-size: 28px; color: #333;">课程</div>
								<div style="font-size: 17px; color: #999; margin-top: 10px;">如何有效用好一本书，教好一门课</div>
							</div>
						</div>
							
						
						<div style=" display: flex;align-items: center;line-height: 1; color: #999; font-size: 20px; cursor: pointer; padding-top: 18px;" @click="toList(4)">
							更多课程<i class="el-icon-arrow-right" style="margin-left: 5px;"></i>
						</div>
					</div>

					<div class="course_list">
						<div class="item" style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;"
							v-for="(item,index) in productData.list" @click="toCourseDetail(item)">
							<img style="width: 100%;" :src="course_img_url" v-if="item.img_url_tt==''" />
							<img style="width: 100%;" :src="item.img_url_tt" v-if="item.img_url_tt!=''" />
							<div class=""
								style="flex: 1; background-color: #F1F1F1; display: flex;flex-direction: column;">
								<div
									style="flex: 1; display: flex;flex-direction: column; padding: 15px 10px 10px 15px;">
									<div style="font-size: 20px; font-weight: 600; line-height: 1.2; ">{{item.title}}
									</div>
									<div style="font-size: 18px;line-height: 1; margin-top: 8px;">主讲人：{{item.author}}
									</div>
								</div>
								<div
									style="height: 40px;  border-radius: 0 0 20px 20px; border-top: 1px solid #E8E8E8; display: flex; justify-content: space-between;  ; align-items: center; padding:0 17px 0 20px;">
									<div style="display: flex;font-size: 13px; align-items: center;">
										<div
											style="background-color: #c93a2e;color: #fff; padding:2px 8px; border-radius:20px;">
											{{item.res_count}}小节
										</div>
										<div style=" margin-left: 8px;"><i class="el-icon-pie-chart"
												style="margin-right: 2px;"></i>{{item.res_length}}</div>
									</div>
									
									<div v-if="item.is_office_product != 1">
										<div style="font-weight: 600; font-size: 17px; color: #c93a2e;"
											v-if="item.price!='0.00'">¥{{item.price}}</div>
										<div style="font-weight: 600; font-size: 17px; color: #c93a2e;"
											v-if="item.price=='0.00'">免费</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 教研室 -->
			<div
				style="  display:flex; z-index: 333; position: relative; justify-content: center; align-items: center; width: 100%; height: 560px;box-shadow: inset 0px -20px 20px rgba(0, 0, 0, 0.04)">
				<!-- <img src="../../assets/images/bg_index_edu_01.png"  style="min-width: 100%; overflow: hidden;"/> -->
				<div style="display: inline-flex; width: 1200px;height: 560px; ">
					<div style="display: flex;flex-direction: column;">
						<div style="display: flex; margin: 45px 0 65px 0;">
							<div class=""
								style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">

							</div>
							<div style="display: flex;flex-direction: column; line-height: 1;">
								<div style="font-size: 28px; color: #333;">数字教研室</div>
								<div style="font-size: 17px; color: #999; margin-top: 10px;">提升教学实践系统能力的成长圈子</div>
							</div>
						</div>

						<div style="display: flex;flex-direction: column;">
							<div style="display: flex; font-size: 40px; font-weight: 600; color: #333;">
								《投资学原理及应用》
							</div>
							<div style="display: flex; font-size: 28px;  color: #333; margin-top: 35px;">
								贺显南
							</div>
							<div style="display: flex; font-size: 20px;  color: #999; line-height: 1.4;">
								广东外语外贸大学金融学院教授<br />
								《投资学原理及应用》作者
							</div>
							<div @click="toRoomDetail"
								style=" display: inline-flex; justify-content: center;align-items: center;color: #fff; font-size: 20px; width: 132px; height: 40px; border-radius: 40px; background:linear-gradient(to right,#c93a2e,#F6892E); margin-top: 50px;cursor: pointer;">
								了解详情
							</div>
						</div>
					</div>
					<div style="flex: 1;height: 560px; z-index: 1;  display: flex; flex-direction: column;justify-content: flex-end; align-items: center;">
						<img :src="digital_office_img_url"
							style="width: 520px; margin-top: 30px;" />
					</div>
				</div>
			</div>




			
			<!-- 名师团 -->
			<div style="display: flex; flex-direction: column;width: 100%; background-color: #FDEBDB; z-index: 4; align-items: center;">
				
				
				<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
					<div style="display: flex; margin: 60px 0 20px 0;">
						<div class=""
							style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
				
						</div>
						<div style="display: flex;flex-direction: column; line-height: 1;">
							<div style="font-size: 28px; color: #333;">名师团</div>
							<div style="font-size: 17px; color: #999; margin-top: 10px;">改变自己，从关注自己身边的榜样开始</div>
						</div>
					</div>
				</div>
				
				
				
				
				<div v-if="typeSelected"
					style="display:flex; z-index: 333; width: 100%;  position: relative; justify-content: center; align-items: center; height: 400px;">
					<!-- <img src="../../assets/images/bg_index_teacher_01.png"  style="min-width: 100%; overflow: hidden;"/> -->
					<div
						style="display: flex;  width: 1200px; height: 400px; justify-content: center;  align-items: center;">
						<div style="width: 320px;line-height: 1;display: inline-flex;flex-direction: column;">
							<div style="color: #C63D33; font-size: 48px;font-weight: 600;">{{typeSelected.title}}</div>
							<div style="color: #999; font-size: 23px;margin-top: 15px;">{{typeSelected.title_en}}</div>
							<!-- <div
								style="color: #999; font-size: 17px; text-align: justify;line-height: 1.2; margin-top: 30px;">
								改变自己，从关注自己身边的榜样开始</div> -->
						</div>
						<div style="flex: 1; display: flex; align-items: flex-end;height: 400px;">
							<img :src="typeSelected.img_url" style=" height: 365px;" />
						</div>
					</div>
				</div>
				
			</div>
			



			<div class="type_data_box">
				<img class="icon_arrow" src='../../assets/images/icon_arrow_left.png' />
				<div class="type_data_item" :class="{'disabled':item.disabled,'actived':item.title == typeSelected.title}" v-for="(item,index) in typeData" @click="changeType(item)">
					<div class="type_data_title" style="">{{item.title}}</div>
					<div class="type_data_title_en">{{item.title_en}}</div>
				</div>
				<img class="icon_arrow" src='../../assets/images/icon_arrow_right.png' />
			</div>



		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				banner:[],
				course_img_url:require("../../assets/images/cover.png"),
				digital_office_img_url:require("../../assets/images/bg_index_edu_02.png"),
				typeData: [{
					title: "机械",
					title_en: "Mechanical",
					img_url:"",
					disabled:true,
				}, {
					title: "电气及自动化",
					title_en: "Electrical and Automation",
					img_url:"",
					disabled:true,
				}, {
					title: "电子信息",
					title_en: "Digital Information",
					img_url:"",
					disabled:true,
				}, {
					title: "计算机",
					title_en: "Computer",
					img_url:"",
					disabled:true,
				}, {
					title: "汽车",
					title_en: "Automobile",
					img_url:"",
					disabled:true,
				}, {
					title: "经济",
					title_en: "Economics",
					img_url: require("../../assets/images/bg_index_teacher_02_new.png"),
					disabled:false,
				}, {
					title: "管理",
					title_en: "Management",
					img_url:require("../../assets/images/bg_index_teacher_01_new.png"),
					disabled:false,
				}],
				typeSelected:null,
				screenWidth: (document.documentElement.clientWidth * 720) / 1920 + 'px',

			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getBanner();
			this.getProductList();
			this.changeType(this.typeData[5]);
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					if (document.documentElement.clientWidth > 960) {
						this.screenWidth = (document.documentElement.clientWidth * 720) / 1920 + 'px';
					}
				})();
			}
		},
		methods: {
			toList(product_type){
				this.GoToChangeTab(4);
			},
			changeType(item){
				if(item.disabled){
					return;
				}
				this.typeSelected = item;
			},
			toCampDetail(item) {
				if (!item.id) {
					this.$alert('特训营正在紧张筹备中，敬请期待······', '温馨提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				this.GoToDetail(item.product_type,item.id);
			},
			toRoomDetail(item) {
				/*
				var url = "/teachRoom/detail";
				var query = { id: item.id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				*/
				var url = "/teachRoom/detail";
				var query = {
					id: '9ea73161ee334b79a2ef0575316a088b'
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			toCourseDetail(item) {
				var url = '/course/detail';
				var query = {
					id: item.id,
					//tt_camp_id: this.id
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			toWebcastDetail(item) {
				if (!item.webcast_url) {
					this.$alert('直播间正在创建中..', '提示', {
						confirmButtonText: '确定'
					});
				} else {
					window.location.href = item.webcast_url;
				}
			},

			clickTag(name) {
				var url = "/teachRes/taglist";
				var query = {
					tag_name: name
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_course_list_get;
				this.Ajax(url, {
					page_index: 1,
					page_size: 8
				}, (data) => {
					if (that.productData.total == 0) {
						that.productData.total = data.total;
					}
					if (that.productData.count == 0) {
						that.productData.count = data.count;
					}
					that.productData.list = data.list;

					//console.log('productData' + JSON.stringify(that.productData))
				});
			},
			getBanner(){
				var that = this;
				var url = apiUrl.tt_camp_list_get;
				this.Ajax(url, {
					page_index: 1,
					page_size: 20,
					order_by: '',
					state: 1
				}, (data) => {
					that.banner = data.list;
				});
			}
		},
	};
</script>

<style>
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}
</style>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.body-main {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #fff;
			//padding: 10px 0;
			box-sizing: border-box;
		}
		
		.margin_right_10{
			margin-right: 10px;
		}
		
		.type_data_box {
			display: flex;
			width: 100%;
			height: 220px;
			justify-content: space-around;
			align-items: center;
			background-color: #fff;

			.icon_arrow {
				width: 30px;
				height: 30px;
				cursor: pointer;
			}

			.type_data_item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 2px solid #ccc;
				width: 160px;
				height: 140px;
				
				&.disabled{
					.type_data_title{
						color: #ccc;
					}
					.type_data_title_en {
						font-size: 12px;
						color: #ddd;
					}
				}
				
				&:not(.disabled):hover,
				&.actived{
					background-color: #f1f1f1;
					cursor: pointer;
				}
				.type_data_title {
					font-size: 21px;
					color: #333;
				}

				.type_data_title_en {
					font-size: 12px;
					color: #999;
				}
				
				
			}
		}

		.course_list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.item {
				width: 280px;
				height: 376px;
				//height: 325px;
				//border: 1px solid #eee;
				display: flex;
				flex-direction: column;
				//box-shadow: 5px 5px 10px #eee;
				//box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				margin-right: 20px;
				margin-bottom: 40px;
				cursor: pointer;
				overflow: hidden;

				&:nth-child(4n+4) {
					margin-right: 0;
				}

				&:hover {
					//box-shadow:0 1px 1px 1px 10px #666;
					//box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.1);
				}

				.cover {
					width: 285px;
					height: 220px;
					//height: 165px;
				}


				.mes {
					width: 100%;
					display: flex;
					flex-direction: column;
					line-height: 1;
					padding-left: 18px;
					box-sizing: border-box;
					margin: 0;
					position: relative;

					.title {
						margin-top: 15px;
						height: 20px;
						margin-left: -18px;
						font-size: 20px;
						color: #333;
						font-weight: 600;
						border-left: 8px solid #333;
						padding-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}


					.anchor {
						font-size: 17px;
						margin-top: 10px;
						color: #333;
						display: flex;
					}

					.res_info {
						display: flex;
						justify-content: flex-start;
						font-size: 12px;
						margin-top: 15px;
						position: relative;

						.res_count {
							background-color: #333;
							color: #fff;
							padding: 5px 8px;
							display: inline-flex;
							border-radius: 4px 0 0 4px;
						}

						.res_length {
							background-color: #3CD0BA;
							color: #01786D;
							padding: 5px 8px;
							display: inline-flex;
							border-radius: 0 4px 4px 0;
						}
					}

					.exts {
						display: flex;
						align-items: flex-end;
						margin-top: 25px;

						.price {
							font-size: 21px;
							color: #333;
							margin-right: 8px;
							display: inline-flex;
						}

						.readers {
							font-size: 12px;
							color: #3CD0BA;
							display: inline-flex;
						}
					}

					.icon_play {
						width: 150px;
						height: 150px;
						margin: 0;
						position: absolute;
						z-index: 2;
						right: -24px;
						bottom: -24px;
					}
				}
			}
		}


	}
</style>