<template>
	<div style="display: flex;flex-direction: column;">
		<div class="" style=" display: flex; justify-content: space-between; align-items: flex-end;font-weight: 600; line-height: 1;">
			<div style="font-size: 24px;  border-left: 5px solid #333;color: #333;padding-left: 12px;" v-if="menu_id == 2">教学计划</div>
			<div style="font-size: 24px;  border-left: 5px solid #333;color: #333;padding-left: 12px;" v-if="menu_id == 3">课程思政实施方案</div>
			<div style="font-size: 24px;  border-left: 5px solid #333;color: #333;padding-left: 12px;" v-if="menu_id == 7">
				<span v-if="id == 'b80b4134102845ec8ed5b0762d90a38e'">教学重难点</span>
				<span v-if="id != 'b80b4134102845ec8ed5b0762d90a38e'">教学设计</span>
			</div>
		</div>


		<div class="" style="width: 100%; margin: 25px 0; display: flex;flex-direction: column;">
			<div class="" style=" width: 100%; height: 449px; border: 0 solid #fff; background-color: #fff; box-sizing: border-box; position: relative; ">

				<div v-if="selected && selected.res_type ==6 && selected.res_url" style="display:flex; width: 100%; height: 449px; z-index: 2; justify-content: center; align-items: center;">
					<iframe :src="selected.res_url" scrolling="auto" frameborder="0" width="100%" height="100%" align="center"></iframe>
					<div @click="fullscreenClick" style="position: absolute; width: 30px; height: 30px; border-radius: 50%; background-color: #2C2E30; bottom: 30px; right: 30px;display: flex; flex-direction: column;justify-content: center;align-items: center; cursor: pointer;">
						<i class="el-icon-full-screen" style="color: #ccc; font-weight: 600;"></i>
					</div>
				</div>

				<div v-if="show" class="ppt_chapters_scroll_y_box"
					style="z-index: 9; background-color: #eee; padding: 5px 15px; box-sizing: border-box ;box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);position: absolute;right: -10px; bottom: -15px; width: 316px; height: 360px; display: inline-flex;flex-direction: column; ">
					<div class="ppt_chapters_scroll_y"
						style=" width: 100%;  overflow-y: scroll; padding-right: 15px; box-sizing: border-box;">
						<div class="chapter-item" :class="{'actived':selected && selected.id == item.id}"
							v-for="(item,index) in chapterList" @click="changeChapter(item)">
							<div class="title">
								{{item.title}}
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="" style="width: 100%; height: 50px; background-color: #333;display: flex;">
				<div class="" style="flex: 1; display: flex;align-items: center; color: #fff; padding: 0 20px; box-sizing: border-box;">
					<i class="el-icon-document" style="font-size: 20px;margin-right: 5px;"></i><span v-if="selected && selected.title">{{selected.title}}</span>
				</div>
				<div @click="changeShow" class=""
					style="width: 140px; height: 100%; background-color: #CE671B; color: #fff; font-size: 20px; display: flex;justify-content: center;align-items: center;cursor:  pointer;">
					文件目录 <i class="iconfont icon-list" style="margin-left: 8px;font-size: 18px; "></i>

				</div>
			</div>
		</div>

		
		<!-- 全屏 -->
		<el-dialog v-if="zoomShow" :visible.sync="zoomShow" destroy-on-close :fullscreen="true">
			<!-- pdf浏览器 -->
			<div class="full-height-body" style=" display: block; width: 100%; height:100%;overflow: hidden; " v-if="selected.res_type ==6 && selected.res_url">
				<iframe :src="selected.res_url" scrolling="auto" frameborder="0" width="100%" height="100%" align="center"></iframe>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		props: {
			myid: String,
			mymenu: String,
		},
		data() {
			return {
				id: this.myid,
				menu_id: this.mymenu,
				chapterList: [],
				show: false,
				selected: null,
				zoomShow: '',
			};
		},
		created() {
			document.title = "教学计划 - 数字教研室 - 机工教师特训营";
			this.getChapters();
		},
		methods: {
			fullscreenClick(){
				var that = this;
				
				that.$nextTick(() => {
					that.zoomShow = true;
				})
			},
			changeShow() {
				this.show = !this.show;
			},
			changeChapter(item) {
				this.show = false;
				this.getRes(item);
			},
			getChapters() {
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.id,
					menu_id: this.menu_id
				}, (data) => {
					that.chapterList = data;
					console.log(JSON.stringify(that.chapterList))
					that.getRes(that.chapterList[0])
				});
			},
			getRes(item){
				var that = this;
				var url = apiUrl.digital_office_ext_detail_get;
				this.Ajax(url, {
					id: item.id,
				}, (data) => {
					if(data.res_type == 6){
						that.selected = {
							title: data.title,
							res_url: `/pdf.js/4.7.76/web/viewer.html?file=${ encodeURIComponent(data.res_url) }`,
							res_type: data.res_type,
							id: data.id
						};
					}
				});
			},
			
			
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-button--primary {
		background-color: #CE671B !important;
		border-color: #CE671B !important;
	}

	.ppt_chapters_scroll_y {
		padding-bottom: 100px !important;
	}

	.ppt_chapters_scroll_y::-webkit-scrollbar {
		width: 3px;
	}

	.ppt_chapters_scroll_y::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #3DD2BC;
		border-radius: 6px;
	}

	.chapter-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
		box-sizing: border-box;
		cursor: pointer;

		.title {
			font-weight: 600;
			color: #333;
		}

		.sub_title {
			margin-top: 5px;
			color: #999;
		}

		&:hover,
		&.actived {
			background-color: #E4E4E4;

			.title {
				color: #333;
			}

			.sub_title {
				color: #5d5d5d;
			}
		}

		&:last-child {
			border-bottom: 0;
		}
	}
</style>