<template>
	<div class="page-main" style="position: relative;" :id="backtop_id">
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 1">
			<!-- 报名 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						报名
					</div>
				</div>
			</div>
			
			<div v-if="detailData" style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px; margin-bottom: 60px; ;line-height: 1;">
				<div style="display: flex; justify-content: space-between;padding-bottom: 22px; border-bottom: 1px solid #eee;">
					<div style="display: flex; flex-direction: column;font-size: 18px; color: #333;">
						<div style="font-weight: 600;">项目</div>
						<div style="margin-top: 40px;">{{detailData.title}}</div>
					</div>
					<div style="display: flex; flex-direction: column; justify-content: flex-end;" v-if="price">
						<div style="font-size: 12px; color: #ff9000;">总计</div>
						<div style="font-size: 18px; color: #333; margin-top: 24px;">{{price}} x {{gift_count}} = ￥{{total_fee}}</div>
					</div>
				</div>

				<div style="display: flex;flex-direction: column;">
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						个人信息
					</div>
					
					<div style="display: inline-block; width: 640px;">
						<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="240px" >
						
							<div v-for="(domain, index) in dynamicValidateForm.domains" :key="domain.key">
								<el-divider content-position="left" v-if="index>0">参学人员 +{{index}}</el-divider>
								
								
								<el-form-item :prop="'domains.' + index + '.name'"  label="姓名" :rules="[{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.name" placeholder="请输入姓名"></el-input>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.sex'" label="性别" :rules="[{ required: true, message: '请选择性别', trigger: 'change' }]">
									<el-radio-group v-model="domain.sex">
									      <el-radio label="男"></el-radio>
									      <el-radio label="女"></el-radio>
									    </el-radio-group>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.mobile'" label="手机" :rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
									<el-input @blur="inputMobileBlurEvent" v-model="domain.mobile" placeholder="请输入手机号码"></el-input>
								</el-form-item>
								
								
								<el-form-item :prop="'domains.' + index + '.job_type'" label="身份" :rules="[{ required: true, message: '请选择身份类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.job_type" @input="jobTypeChangeEvent" :disabled="domain.job_type_disabled">
									    <el-radio label="教师"></el-radio>
									    <el-radio label="学生"></el-radio>
										<el-radio label="其他"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.job_type == '教师' || domain.job_type == '学生'" :prop="'domains.' + index + '.school'" label="所在学校" :rules="[ { required: true, message: '请输入学校', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.school" placeholder="请输入学校"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师' || domain.job_type == '学生'" :prop="'domains.' + index + '.college'" label="学院" :rules="[ { required: true, message: '请输入学院', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.college" placeholder="请输入学院"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.title'" label="职称" :rules="[ { required: true, message: '请输入职称', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.title" placeholder="请输入职称"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.duty'" label="职务" :rules="[ { required: true, message: '请输入职务', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.duty" placeholder="请输入职务"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.teaching_length'" label="教龄" :rules="[{ required: true, message: '请输入教龄', trigger: ['blur', 'change'] },{ type: 'number', message: '教龄必须为数字类型', trigger: ['blur', 'change']}]">
									<el-input v-model.number="domain.teaching_length" placeholder="请输入教龄"></el-input>
								</el-form-item>
								
								<el-form-item v-if="domain.job_type == '其他'" :prop="'domains.' + index + '.company'" label="公司(单位)名称" :rules="[ { required: true, message: '请输入公司或单位名称', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.company" placeholder="请输入公司(单位)名称"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '学生' || domain.job_type == '其他'" :prop="'domains.' + index + '.education'" label="学历" :rules="[{ required: true, message: '请选择学历', trigger: 'change' }]">
									<el-radio-group v-model="domain.education">
									    <el-radio label="专科"></el-radio>
									    <el-radio label="本科"></el-radio>
										<el-radio label="研究生"></el-radio>
									</el-radio-group>
								</el-form-item>
								
					
								
								<div style=" padding:5px 0 10px 120px; box-sizing: border-box;">
									<el-divider content-position="left">会议服务选择</el-divider>
								</div>
								
								<el-form-item v-if="detailData.is_package==1"
									:prop="'domains.' + index + '.package_items'" label="参会"
									:rules="[{ required: true, message: '请选择您想要参加的会议', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.package_items" style="margin-top:5px"
										@change="changePackageItems" :disabled="domain.package_items_disabled">
										<el-radio v-for="package_item in detailData.package_items"
											:label="package_item.id">{{package_item.title}}
											{{package_item.price}}</el-radio>
									</el-radio-group>
								</el-form-item>
									
								<!-- 非纯线上 -->
								<div v-if="domain.is_show_more">
	
								<el-form-item v-if="detailData && detailData.has_arrival_date == 1 && detailData.form_arrival_items" :prop="'domains.' + index + '.check_in_date'" label="报到日期" :rules="[{ required: true, message: '请选择报到日期', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.check_in_date">
										<el-radio :label="tem" v-for="(tem,dex) in detailData.form_arrival_items"></el-radio>
										<el-radio label="其他"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.check_in_date == '其他'" :prop="'domains.' + index + '.check_in_date_other'" label="其他报到日期" :rules="[ { required: true, message: '请输入其他报道日期', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.check_in_date_other" placeholder="请输入其他报到日期"></el-input>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.diet_type'" label="餐饮" :rules="[{ required: true, message: '请选择餐饮类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.diet_type">
										<el-radio label="清真"></el-radio>
										<el-radio label="非清真"></el-radio>
									 </el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="detailData && detailData.has_hotel == 1 && detailData.form_hotel_items" :prop="'domains.' + index + '.stay_type'" label="住宿" :rules="[{ required: true, message: '请选择住宿类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.stay_type">
										<el-radio label="自行安排"></el-radio>
										<el-radio label="代行预订"></el-radio>
									 </el-radio-group>
									 <div style="width: 260px; text-align: right;color: #C93A2E; margin-top: -18px;">
										 具体以当日房价为准
									 </div>
								</el-form-item>
								
								<el-form-item v-if="detailData.form_hotel_items && domain.stay_type=='代行预订'" :prop="'domains.' + index + '.hotel'" label="酒店" :rules="[{ required: true, message: '请选择您入住的酒店', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.hotel">
										<el-radio :label="tem" v-for="(tem,dex) in detailData.form_hotel_items"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.hotel" :prop="'domains.' + index + '.room_type'" label="请选择入住方式" :rules="[{ required: true, message: '请选择入住方式', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.room_type">
										<el-radio label="单人入住"></el-radio>
										<el-radio label="与指定人员拼房"></el-radio>
										<el-radio label="会务组随机拼房"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.room_type == '与指定人员拼房'" :prop="'domains.' + index + '.room_share_with'" label="同住人姓名/电话" :rules="[ { required: true, message: '请输入同住人姓名/电话', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.room_share_with" placeholder="请输入同住人姓名/电话"></el-input>
								</el-form-item>
								
								<el-form-item v-if="domain.hotel" :prop="'domains.' + index + '.stay_days'" label="入住天数" :rules="[{ required: true, message: '请选择入住方式', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.stay_days">
										<el-radio label="1天"></el-radio>
										<el-radio label="2天"></el-radio>
										<el-radio label="3天"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item  v-if="detailData && detailData.has_visit == 1 && detailData.form_visit_label" :prop="'domains.' + index + '.is_visit'" :label="detailData.form_visit_label" :rules="[{ required: true, message: '请选择您是否参加参观考察', trigger: ['blur', 'change'] }]">
									<div style="line-height: 1.4;margin: 12px 0; color: #999;" v-html="detailData.form_visit_text"></div>
									<el-radio-group v-model="domain.is_visit">
										<el-radio label="是"></el-radio>
										<el-radio label="否"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								</div>
								
								<el-form-item v-if="detailData && detailData.has_cert == 1 && detailData.form_cert_label" :prop="'domains.' + index + '.is_cert'" :label="detailData.form_cert_label"
									:rules="[{ required: true, message: '请选择是否考证', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.is_cert" @input="isCertChangeEvent"
										:disabled="domain.is_cert_disabled">
										<el-radio label="是"></el-radio>
										<el-radio label="否"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="detailData &&  ((detailData.has_cert == 1 && domain.is_cert == '是') || (detailData.has_cert == 0 && detailData.has_id_card == 1))" :prop="'domains.' + index + '.id_card'" label="身份证号码"
									:rules="[{ required: true, message: '请输入身份证号码', trigger: ['blur', 'change'] },{ len:18,message: '请输入正确的身份证号码', trigger: ['blur', 'change'] }]">
									<div v-if="detailData.form_id_card_text" v-html="detailData.form_id_card_text" style="line-height: 1.4;margin: 12px 0;color: #999"></div>
									<el-input v-model="domain.id_card" placeholder="请输入身份证号码"></el-input>
								</el-form-item>
								
								
								<el-form-item label="联系地址" required v-if="detailData &&  ((detailData.has_cert == 1 && domain.is_cert == '是') || (detailData.has_cert == 0 && detailData.has_address == 1))">
									<div v-if="detailData.form_address_text" v-html="detailData.form_address_text" style="line-height: 1.4;margin: 12px 0;color: #999"></div>
			
									<el-col :span="24">
									  <el-form-item :prop="'domains.' + index + '.pca'" :rules="[{ required: true, message: '请选择省/市/区（县）', trigger: ['blur', 'change']}]">
										<el-cascader placeholder="请选择省/市/区（县）" :options="options" v-model="domain.pca"  :props="{label:'name',value:'name',children:'children' }" filterable ></el-cascader>
									  </el-form-item>
									</el-col>
									<el-col :span="24">
									  <el-form-item :prop="'domains.' + index + '.address'" :rules="[{ required: true, message: '请输入详情地址', trigger: ['blur', 'change']}]">
										<el-input v-model="domain.address" placeholder="请输入详细地址"></el-input>
									  </el-form-item>
									</el-col>
								</el-form-item> 
									
							

								<el-button @click.prevent="removeDomain(domain)" v-if="index>0">移除参学人员 +{{index}}</el-button>
							</div>
														
							<div style="margin-top: 30px;" v-if="is_gift_add">
								<el-form-item>
									<!-- <el-button type="primary" @click="submitForm('dynamicValidateForm')">验证填写信息</el-button> -->
									<el-button @click="addDomain">新增参学人员</el-button>
									<!-- <el-button @click="resetForm('dynamicValidateForm')">重置参学人员信息</el-button> -->
								</el-form-item>
								<el-alert
								    title="报名信息填写验证通过"
								    type="success"
									v-if="alert_show1">
								  </el-alert>
							</div>
							<div style=" width: 100%; margin-top: 30px; font-size: 15px; color: #515a6e; padding-left: 160px; box-sizing: border-box;" v-if="!is_gift_add">							  
								  <i class="el-icon-warning-outline" style="margin-right: 5px;"></i>如需添加更多参学人员请联系工作人员
							</div>
							
						</el-form>
						
						<div v-if="detailData.student_price" style="display: flex; flex-direction: column; width: 640px;font-size: 14px; margin-top: 12px; line-height: 1.5;text-align: justify;">
							暂不支持同时添加多种身份的参学者，如有需要请分别使用不同账号提交报名信息或联系工作人员<br>
							温馨提示：学生身份的参学者，参会时请务必携带学生证，会务组会在报到时核验报名信息
						</div>
					</div>
					
					
					<div v-if="total_fee > 0" style="display: flex; font-size: 24px; margin-top: 58px;">
						<el-checkbox v-model="checked" @change="checkedChangeEvent">我要发票</el-checkbox>
					</div>
					
					<div v-if="checked">
						<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0 10px 0;">
							发票信息
						</div>
						
						<div style="display: flex; flex-direction: column; width: 640px;">
							<div style="font-size: 14px; margin: 12px 0 30px 0; line-height: 1.5; text-align: justify;">
								我社统一开具电子普票，会后发送至您预留的邮箱。如需专票，请联系工作人员。<br>
								为避免后续开具的发票无法用于报销，填写发票信息前务必找贵校/贵司财务专员确认最新开票信息。
							</div>
						
							<el-form :model="dynamicValidateForm2" ref="dynamicValidateForm2" label-width="240px">
								<div v-for="(domain, index) in dynamicValidateForm2.domains" :key="domain.key">
									
									<el-form-item v-if="gift_count>1" :prop="'domains.' + index + '.invoice_type'" label="开具方式" :rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
										<el-radio-group v-model="domain.invoice_type" @input="bindInvoiceData">
											  <el-radio label="统一开具" checked></el-radio>
											  <el-radio label="单独开具"></el-radio>
										</el-radio-group>
									</el-form-item>
									
									<el-form-item :prop="'domains.' + index + '.invoice_count'" :border="false" label="发票张数" :rules="[{ required: true, message: '请输入发票张数', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.invoice_count" readonly placeholder="请输入发票张数"></el-input>
									</el-form-item>
									
									<el-form-item :prop="'domains.' + index + '.total_fee'"  label="开票金额" :rules="[{ required: true, message: '请输入开票金额', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.total_fee" readonly placeholder="请输入开票金额"></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.title'"  label="发票抬头" :rules="[{ required: true, message: '请输入发票抬头', trigger: ['blur', 'change'] }]">
										<!-- <el-input v-model="domain.title" placeholder="请输入发票抬头"></el-input> -->
										<el-autocomplete
										      class="inline-input"
										      v-model="domain.title"
										      :fetch-suggestions="querySearch"
										      placeholder="请输入发票抬头"
											  :trigger-on-focus="false"
										      @select="handleSelect"
											  style="width: 380px;"
										    ></el-autocomplete>
											
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.tax_id'" label="发票税号" :rules="[{ required: true, message: '请输入发票税号', trigger: ['blur', 'change'] },{ min: 15, max:20,message: '请输入正确的发票税号', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.tax_id" placeholder="请输入发票税号(三证合一后税号为18位)"></el-input>
									</el-form-item>
									
									
									<el-collapse accordion>
									  <el-collapse-item>
											<template slot="title">
											   地址/电话/开户行/账号(非必填，点击可展开填写)  <i class="header-icon el-icon-info"></i> 
											</template>
											
											<div>
												<el-form-item :prop="'domains.' + index + '.registered_address'" label="地址(非必填)">
													<el-input v-model="domain.registered_address" placeholder="请输入开票信息中的单位地址"></el-input>
												</el-form-item>
												<el-form-item :prop="'domains.' + index + '.registered_contact'" label="电话(非必填)">
													<el-input v-model="domain.registered_contact" placeholder="请输入开票信息中的单位电话"></el-input>
												</el-form-item>								
												<el-form-item :prop="'domains.' + index + '.bank'" label="开户行(非必填)">
													<el-input v-model="domain.bank" placeholder="请输入开票信息中的开户行名称"></el-input>
												</el-form-item>
												<el-form-item :prop="'domains.' + index + '.bank_account'" label="账号(非必填)">
													<el-input v-model="domain.bank_account" placeholder="请输入开票信息中的银行账号"></el-input>
												</el-form-item>
											</div>
									  </el-collapse-item>
									</el-collapse>
									
									
									<el-form-item :prop="'domains.' + index + '.category'" label="发票类目" :rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999"
											v-if="detailData.case_type != 2">
											开具的发票中会议费显示为『*会展服务*会议费』，培训费显示为『*非学历教育服务*{{detailData.title}}培训费』，请根据可开类目选择
										</div>
										<el-radio-group v-model="domain.category">
										      <el-radio label="会议费" v-if="detailData.case_type != 2"></el-radio>
											  <el-radio label="培训费"></el-radio>
											  <!--  
											  <el-radio label="信息服务费"></el-radio>
										      <el-radio label="技术服务费"></el-radio>
											  <el-radio label="其他"></el-radio> 
											  -->
										</el-radio-group>
									</el-form-item>
									<el-form-item v-if="domain.category == '其他'" :prop="'domains.' + index + '.category_other'" label="其他发票类目" :rules="[{ required: true, message: '请输入发票类目', trigger: 'change' }]">
										<el-input v-model="domain.category_other" placeholder="请输入其他发票类目"></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.email'" label="发票接收邮箱" :rules="[{ required: true, message: '请输入邮箱地址', trigger: ['blur', 'change'] },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.email" placeholder="请输入邮箱地址" ></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.mobile'" label="发票接收人手机" :rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.mobile" placeholder="请输入手机号码"></el-input>
									</el-form-item>
									
								
								</div>
								
								<div style="margin-top: 30px;">
									<el-alert
									    title="开票信息填写验证通过"
									    type="success"
										v-if="alert_show2">
									</el-alert>
								</div>
							</el-form>
						</div>
						
						
					</div>
					
				</div>
				
				<div @click="infoConfirm" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">提交报名信息</div>
				</div>
				
			</div>
		</div>
		
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 2">
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
					<div v-if="stateData.result == 1"
						style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						报名成功
					</div>
					<div v-if="stateData.result == 0"
						style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						提交成功
					</div>
				</div>
			</div>
		
			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 50px; margin-bottom: 50px; ;line-height: 1;">
		
				<div style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box; border-bottom: 1px solid #eee;">
					<img src="../../assets/images/icon_success.png" style="width: 120px; height: auto;" />
					<div v-if="stateData.result == 0" style=" font-size: 24px; margin-top: 30px; color: #333;">
						报名信息已提交成功
					</div>
					<div v-if="stateData.result == 1" style=" font-size: 24px; margin-top: 30px; color: #333;">
						报名成功
					</div>
					<div style="width: 640px;display: flex; flex-direction:column;margin-top: 40px; font-size: 17px; line-height: 1.75;color: #333;">
						<div>报名信息如下：</div>
						<div>会议名称：{{detailData.title}}</div>
						<div>会议日期：{{detailData.arrival_at}} 至 {{detailData.stop_at}}</div>
						<div>会议地点：{{stateData.place}}</div>
						<div>参会人数：{{stateData.applyer_count}} 人</div>
						<div>人员名单：{{stateData.applyer}}</div>
						<div v-if="orderData.total_fee == 0">报名费用：免费</div>
						<div v-if="orderData.total_fee > 0">
							<div>报名费用：{{orderData.total_fee}} 元</div>
							<div v-if="stateData.result == 1">缴费状态：已缴费</div>
							<div v-if="stateData.result == 0">缴费状态：未缴费</div>
							<div style="display: flex; justify-content: space-between;" v-if="detailData.is_open_pay == 1 && stateData.pay_method == 0">
								<div>缴费方式确认：未确认</div>
								<div style="display: inline-flex; color:#ca3f1e; border-bottom: 1px solid #ca3f1e; cursor: pointer;" @click="toPay">确认缴费方式</div>
							</div>
							<div style="display: flex; flex-direction: column;" v-if="stateData.pay_method !=0">
								<div style="display: flex; justify-content: space-between;" v-if="stateData.result == 0">
									<div style="display: inline-flex;" v-if="stateData.pay_method == 1">缴费方式确认：在线支付</div>
									<div style="display: inline-flex;" v-if="stateData.pay_method == 2">缴费方式确认：对公转账</div>
									<div style="display: inline-flex;" v-if="stateData.pay_method == 3">缴费方式确认：现场缴费</div>
									<div style="display: inline-flex;" v-if="stateData.pay_method == 4">缴费方式确认：扫码支付</div>
									<div style="display: inline-flex; color:#ca3f1e; border-bottom: 1px solid #ca3f1e; cursor: pointer;" @click="toPay">更换缴费方式</div>
								</div>
								<div style="display: flex; flex-direction: column;" v-if="stateData.result == 1">
									<div style="display: inline-flex;" v-if="stateData.pay_method == 1">缴费方式：在线支付</div>
									<div style="display: inline-flex;" v-if="stateData.pay_method == 2">缴费方式：对公转账</div>
									<div style="display: inline-flex;" v-if="stateData.pay_method == 3">缴费方式：现场缴费</div>
									<div style="display: inline-flex;" v-if="stateData.pay_method == 4">缴费方式：扫码支付</div>
								</div>
							</div>
							
							<div v-if="stateData.result == 0" style="display: flex; flex-direction: column;font-size: 24upx; color: #a0a0a0;margin-top: 24px; line-height: 1.5; text-align: justify;">
								<div v-if="stateData.pay_method == 2">您选择的缴费方式为对公转账，支持公对公转账，也支持个人对公转账</div>
								<div v-if="stateData.pay_method == 3">您选择的缴费方式为现场缴费，会议报到当天现场缴费，现场缴费支持刷卡、支付宝、微信支付。</div>
								<div v-if="detailData.is_open_pay == 0">本次会议采用预报名形式，{{detailData.is_open_pay_message}}，后续缴费功能会自动开放，缴费方式支持微信在线支付、对公转账、现场缴费。</div>
							</div>
						</div>
						
					</div>
					<div v-if="detailData.is_open_pay == 1 && stateData.result == 0" style=" width: 640px;  margin: 32px 0;">
						<div style=" width: 100%; display: flex;flex-direction: row; justify-content:  space-between;">
							<div @click="toPay" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:240px; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
								<div v-if="stateData.pay_method !=2 && stateData.pay_method !=4">立即缴费</div>
								<div v-if="stateData.pay_method == 2">查看转账信息</div>
								<div v-if="stateData.pay_method == 4">查看扫码信息</div>
							</div>
							
							<div @click="toDetail" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:240px; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
								稍后缴费
							</div>
						</div>
						<div v-if="!orderData.media_list || (orderData.media_list && orderData.media_list.length == 0)" style="width: 100%;display: flex;flex-direction: column; margin-top: 40px; ">
							<div style="font-size: 17px;color: #333;line-height: 1.75;">如果您已经通过对公转账或扫码支付的方式完成缴费，请上传缴费凭证，以便工作人员及时确认缴费状态。</div>
							<div style="margin: 10px 0;" v-if="showAlert">
								<el-alert title="请上传缴费凭证截图" type="error" @close="closeAlert"></el-alert>
							</div>
							<div style="width: 480px; margin-top: 16px;">
								<el-upload
									:file-list="fileList"
									list-type="picture"
									accept=".jpg,.jpeg,.bmp,.png"
									action="#"
									drag
									multiple
									:limit="3"
									:auto-upload="false"
									:on-exceed="handleExceed"
									ref="upload"
									:on-change="handlChange"
									:on-remove="handleRemove"
									:before-upload="beforeUpload"
								>
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">将文件拖到此处，或<em>点击选择图片</em></div>
								<div slot="tip" class="el-upload__tip">请选择jpg/png格式的图片，对公转账回执截图或扫码支付完成后的账单截图，至少1张截图</div>
							  </el-upload>
							</div>
							<div style=" width: 100%; display: flex;flex-direction: row; margin-top: 16px;">
								<div @click="uploadConfirmClick" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:240px; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
									确认上传
								</div>
							</div>
						</div>
					</div>
					
					<div v-if="orderData.media_list && orderData.media_list.length>0" style="width: 640px; display: flex; flex-direction:  column;">
						<div style="font-size: 17px;color: #333;line-height: 1.75;">
							<span v-if="stateData.result == 0">缴费凭证已上传，工作人员会尽快确认缴费状态。</span>
							<span v-if="stateData.result == 1">缴费凭证（截图）：</span>
						</div>
						<div style="display: flex; flex-direction: row; margin-top: 16px;">
							<div  style="display: flex; flex-direction: column; justify-content: center; align-items: center;" v-for="(item,index) in orderData.media_list" :key="index">
							    <el-image
							      style="width: 100px; height: 100px; margin-right: 10px; cursor: pointer;"
							      :src="item.img_url_compressed"
								  :preview-src-list="loadPreviewSrcList()"
							      fit="fill"></el-image>
							</div>
						</div>
					</div>
					
					<div v-if="detailData.is_open_study == 1 && stateData.result == 1" style=" width: 640px; display: flex;flex-direction: column; margin-top: 60px;">
						<div @click="toStudyClick" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:100%; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
							开启特训营的学习
						</div>
					</div>
					
					
					<div style=" width: 640px; font-size: 17px;color: #999; line-height: 1.3; margin-top: 50px; display: flex; flex-direction: column; ;justify-content: center;align-items: center; ">
						<div>报名后，请各位老师扫描下方二维码添加客服微信号，添加好友时请备注：会议名称+姓名+学校，邀请您进本次会议专属群，我们将在群内发布本次会议更多学习信息。</div>
						<div style="margin-top: 30px;" v-if="detailData">
							<img v-if="detailData.kf_qrcode_img_url" :src="detailData.kf_qrcode_img_url" style="width: 320px;" />
							<img v-if="!detailData.kf_qrcode_img_url" src="../../assets/images/qrcode_kf.jpg" style="width: 320px;" />
						</div>
					</div>
		
				</div>
				<div style="display: flex; flex-direction: column; padding:40px 0; box-sizing: border-box;">
					<div style="display: flex;flex-direction: column;">
						<div style="font-size: 18px; color: #666;line-height: 1.4; margin-top: 40px;">
							推荐您关注我们的微信公众号，<br>
							获取教师系统能力特训营及数字教研室的最新活动信息，<br>
							结识更多优秀教师好友。
						</div>
						<div style="display: flex;  justify-content: space-between; margin-top: 40px;">
							<div style="display: flex;align-items: flex-end; ">
								<div style="width: 132px;height: 132px;border: 1px solid #eee; background-color: #eee; margin-right: 20px;">
									<img src="../../assets/images/qrcode_for_cmptt.jpg" style="width: 130px;" />
								</div>
								<div style="font-size: 18px;">
									<i class="el-icon-caret-left"></i>
									扫码关注公众号
								</div>
							</div>
							<div style="display: flex;align-items: flex-end;">
								<img src="../../assets/images/logo.png" style="width: 180px; height: auto;" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 3">
			<!-- 支付 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						缴费
					</div>
				</div>
			</div>
			
			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px; margin-bottom: 60px;line-height: 1;">
				<div style="font-size: 18px;color: #333; font-weight: 600;">请选择缴费方式</div>
				<div class="pay_tabs">
					
					<div v-if="detailData.is_online_pay == 1" class="pay_tab" :class="{'actived':pay_tab_index == 1}" @click="changePayTab(1)">
						<img src="../../assets/images/icon_weixin.png" style="width: 50px;margin-right: 10px;" />
						微信支付
						<div v-if="pay_tab_index == 1" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
					
					<div v-if="detailData.is_scan_pay == 1 && detailData.payment_qrcode_img_url" class="pay_tab" :class="{'actived':pay_tab_index == 4}" @click="changePayTab(4)">
						<img src="../../assets/images/icon_qr_scan.png" style="width: 50px; margin-right: 10px;" />
						扫码支付
						<div v-if="pay_tab_index == 4" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
					<div class="pay_tab" :class="{'actived':pay_tab_index == 2}" @click="changePayTab(2)">
						<img src="../../assets/images/icon_transfer.png" style="width: 50px; margin-right: 10px;" />
						对公转账
						<div v-if="pay_tab_index == 2" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
					<div v-if="stateData.is_offline_pay == 1" class="pay_tab" :class="{'actived':pay_tab_index == 3}" @click="changePayTab(3)">
						<img src="../../assets/images/icon_face_to_face.png" style="width: 50px; margin-right: 10px;" />
						现场缴费
						<div v-if="pay_tab_index == 3" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
				</div>


				<div v-if="pay_tab_index == 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 65px;">
					<div style="border: 1px solid #eee; background-color: #eee; width: 232px; height: 232px;">
						<div ref="qrcode" style="width: 230px; height: 230px;"></div>
					</div>
					<div style="font-size: 24px; margin-top: 40px; " v-if="orderData && orderData.total_fee">
						总金额：¥{{orderData.total_fee}}
					</div>
					<div style="font-size: 22px;color: #999; margin-top:15px;">
						请使用微信扫码支付
					</div>
				</div>
				
				
				<div v-if="pay_tab_index == 4" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 65px;">
					<div style="border: 1px solid #eee; background-color: #eee; width: 300px;  display: inline-flex;">
						<img :src="detailData.payment_qrcode_img_url" style="width: 300px; height: auto;"/>
					</div>
					<div style="font-size: 24px; margin-top: 40px;text-align: center;" v-if="orderData && orderData.total_fee">
						总金额：¥{{orderData.total_fee}}
					</div>
					<div style="font-size: 22px;color: #999; margin-top:15px;">
						请使用微信或支付宝扫码支付
					</div>
				</div>
				
				
				<div v-if="pay_tab_index == 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 100px; line-height: 1;">
					<div style="font-size: 18px; line-height: 2;" ref="textDiv">
						<span style="font-weight: 600;" v-if="orderData && orderData.total_fee">打款金额：</span>¥ {{orderData.total_fee}}<br>
						<span style="font-weight: 600;">收款账号：</span>0200 0014 0920 0070 931<br>
						<span style="font-weight: 600;">收款公司名称：</span>机械工业出版社有限公司<br>
						<span style="font-weight: 600;">开户行：</span>中国工商银行北京百万庄支行<br>
						<span style="font-weight: 600;" v-if="orderData && orderData.remark">备注信息：</span>{{orderData.remark.replace('机工特训营','机工教育会议')}}<br>
						<span style="color: #999;">温馨提示：对公转账支持公对公转账，也支持个人对公转账</span>
					</div>
					<div @click="copyText" style="width: 160px; height: 36px; border-radius: 10px;  background-color: #FCA459;color: #fff; font-size: 18px; display: flex;justify-content: center; align-items: center; margin-top: 35px; margin-bottom: 40px; cursor: pointer;">
						一键复制转账信息
					</div>
				</div>
				<div v-if="pay_tab_index == 3 && detailData" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 100px; line-height: 1;">
					<div style="font-size: 24px; line-height: 2; width: 500px;">
						温馨提示：<br>您选择的缴费方式为现场缴费，会议报到当天现场缴费，现场缴费支持刷卡、支付宝、微信支付。
					</div>
				</div>
				<div v-if="pay_tab_index == 1 && detailData.payment_type == 1" @click="finishConfirm" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">已完成支付</div>
				</div>

				
				<div v-if="pay_tab_index != 1 || (pay_tab_index == 1 && detailData.payment_type == 2)" @click="updateOrderInfo(pay_tab_index)" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">确认缴费方式</div>
				</div>
				<div style="font-size: 17px; margin-top: 50px;color: #999; display: flex; flex-direction: column; ;justify-content: center;align-items: center; ">
					<div style="width: 500px; text-align: justify; line-height: 1.5;">报名后，请各位老师添加下方客服微信号，添加好友时请备注：会议名称+姓名+学校，邀请您进本次会议专属群，我们将在群内发布本次会议更多学习信息。</div>
					<div style="margin-top: 30px;">
						<img v-if="!detailData.kf_qrcode_img_url" src="../../assets/images/qrcode_kf.jpg" style="width: 280px;" />
						<img v-if="detailData.kf_qrcode_img_url" :src="detailData.kf_qrcode_img_url" style="width: 280px;" />
					</div>
				</div>
			</div>
		</div>
		
	
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	
	let pca = require("../../js/pca-code.json");
	

	export default {
		data() {
			return {
				dynamicValidateForm: {
					domains: [{
						package_items: '',
						package_items_title: '',
						package_items_disabled: false,
						is_show_more: true,
						name: '',
						mobile: '',
						sex: '',
						job_type: '',
						school: '',
						college: '',
						title: '',
						duty: '',
						teaching_length: '',
						company: '',
						education: '',
						diet_type: '非清真',
						check_in_date: '',
						check_in_date_other: '',
						stay_type: '',
						hotel: '',
						room_type_before: '',
						room_type: '',
						room_type_other: '',
						room_share_with: '',
						stay_days: '',
						is_visit: '', 
						id_card: '',
						is_cert: '',
						is_cert_disabled: false,
						pca: '',
						province: '',
						city: '',
						area: '',
						address: '',
					}]
				},
				dynamicValidateForm2: {
					domains: [{
						invoice_type: '统一开具',
						invoice_count: '',
						title: '',
						tax_id: '',
						category: '会议费',
						category_other: '',
						bank: '',
						bank_account: '',
						registered_address: '',
						registered_contact: '',
						email: '',
						mobile: '',
						gift_buy_count:'',
						price:'',
						total_fee: '',
					}]
				},
				checked: true, //是否开具发票
				page_index : 1,
				pay_tab_index: 1,
				id: null,
				detailData: null,
				gift_count: 1,
				is_gift_add: true,
				total_fee:0,
				price:0,
				alert_show1:false,
				alert_show2:false,
				search: {
					id: null,
					case_type: 2,
					gift_buy_count: 1,
					product_type: 27,
				},
				/*
				orderData: {
					total_fee:'1280',
					out_trade_no:'17110695997V5M5W',
					remark:'',
					qrcode_url:'',
				},*/
				orderData:null,
				stateData: null,
				qrcode: null,
				isLogin:true,
				backtop_id:Date.now().toString(10),
				options: pca,
				invoice_title_list: null,
				showAlert: false,
				fileList : [],
			};
		},
		created() {
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			if(!this.isLogin){
				//window.top.location.href = "/login";
				this.$router.push({ path: '/login' });
				return;
			}
		   var option = {
		   	id: this.$route.query.id || "",
		   };
		   if (option.id) {
			 this.id = option.id;
			 this.search.id = this.id;
		   	 this.getDetail();
			 this.getInvoiceTitleList();
		   }
		},
		components: {
			qrcode: QRCode,
		},
		methods: {
			toDetail(){
				this.GoToDetail(this.detailData.product_type,this.detailData.id);
			},
			toPay(){
				this.page_index = 3;
				
				if(this.stateData.pay_method!=0){
					this.changePayTab(this.stateData.pay_method);
				}else{
					if(this.detailData.is_online_pay==1){
						this.changePayTab(1);
					}else if(this.detailData.is_scan_pay==1){
						this.changePayTab(4);
					}else{
						this.changePayTab(2);
					}
				}
			},
			copyText() {
				var text='';
				text +='打款金额：¥ '+this.orderData.total_fee+'\r\n';
				text +='收款账号：0200 0014 0920 0070 931\r\n';
				text +='收款公司名称：机械工业出版社有限公司\r\n';
				text +='开户行：中国工商银行北京百万庄支行\r\n';
				text +='备注信息：'+ this.orderData.remark.replace('机工特训营','机工教育会议') +'\r\n';
				
			     this.$copyText(text).then((res)=>{
					  //console.log("内容已复制到剪切板")
					  this.$alert('内容已复制到剪切板', '提示');
				 }).catch((err)=>{
					 //console.log("抱歉，复制失败")
					 this.$alert('抱歉，复制失败', '提示');
				 })
			},
			changePackageItems(e) {
				this.dynamicValidateForm.domains.forEach((item, index) => {
					item.package_items = e;
					item.package_items_title = this.detailData.package_items.filter(i => i.id == e.toString())[0].title;
					if (index != 0) {
						item.package_items_disabled = true;
					}

					if(this.detailData.case_type != 2){
						if(item.package_items_title.indexOf('线上')>-1){
							item.is_show_more =false;
						}else{
							item.is_show_more =true;
						}
					}else{
						item.is_show_more = false;
					}
				})
				this.price = parseFloat(this.detailData.package_items.filter(i => i.id == e.toString())[0].price);
				this.total_fee = this.price * this.gift_count * 1.00;
				this.bindInvoiceData();
				this.isGiftAdd();
			},
			inputMobileBlurEvent(){
				var mobile = this.dynamicValidateForm2.domains[0].mobile;
				if(!mobile){
					this.dynamicValidateForm2.domains[0].mobile = this.dynamicValidateForm.domains[0].mobile;
				}
			},
			bindInvoiceData(){
				if (this.total_fee && this.total_fee > 0) {
					this.checked = true;
				} else {
					this.checked = false;
				}
				
				var type = this.dynamicValidateForm2.domains[0].invoice_type;
				if(type){
					if(type == "单独开具"){
						this.dynamicValidateForm2.domains[0].invoice_count = this.gift_count;
						this.dynamicValidateForm2.domains[0].total_fee = this.price.toString();
						this.dynamicValidateForm2.domains[0].gift_buy_count = 1;
						this.dynamicValidateForm2.domains[0].price = this.price.toString();
					}else{
						this.dynamicValidateForm2.domains[0].invoice_count = 1;
						this.dynamicValidateForm2.domains[0].total_fee = this.total_fee.toString();
						this.dynamicValidateForm2.domains[0].gift_buy_count = this.gift_count;
						this.dynamicValidateForm2.domains[0].price = this.price.toString();
					}
				}
			},
			isGiftAdd() {
				var next_total_fee = this.price * (this.gift_count + 1) * 1.00;
				if (next_total_fee > 6000.00) {
					this.is_gift_add = false;
				} else {
					this.is_gift_add = true;
				}
			},
			scrollTop(){
				this.backtop_id = Date.now().toString();
				this.$nextTick(()=>{
					//window.scrollTop(0,0);
					window.location.hash =this.backtop_id;
				});
			},
			toStudyClick(){
				var url = "/trainingCamp/study";
				var query = { id: this.id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			checkedChangeEvent(e) {
				if (!e) {
					this.checked = true;
					this.$confirm('您确认不需要开具发票么，确认后会务组将不再为您开具发票?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$message({
							type: 'success',
							message: '设置成功!'
						});
						this.checked = false;
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}
			},
			jobTypeChangeEvent(){
				//有学生价时，身份变更时，定价更新
				if(this.detailData.student_price){
					
					if(!this.dynamicValidateForm.domains[0].job_type){
						var _job_type='';
						this.dynamicValidateForm.domains.forEach((item,index)=>{
							if(item.job_type){
								_job_type = item.job_type;
							}
						})
						this.dynamicValidateForm.domains[0].job_type = _job_type;
					}
					
					this.dynamicValidateForm.domains.forEach((item,index)=>{
						item.job_type = this.dynamicValidateForm.domains[0].job_type;
						if(index!=0){
							item.job_type_disabled = true;
						}
					})

					if(this.dynamicValidateForm.domains[0].job_type == '学生'){
						this.price = parseFloat(this.detailData.student_price);
					}else{
						this.price = parseFloat(this.detailData.price);
					}
					this.total_fee = this.price * this.gift_count * 1.00;
					this.bindInvoiceData();
					
				}
			},
			isCertChangeEvent() {
				if (this.detailData.cert_fee) {
			
					if (!this.dynamicValidateForm.domains[0].is_cert) {
						var _is_cert = '';
						this.dynamicValidateForm.domains.forEach((item, index) => {
							if (item.is_cert) {
								_is_cert = item.is_cert;
							}
						})
						this.dynamicValidateForm.domains[0].is_cert = _is_cert;
					}
			
					this.dynamicValidateForm.domains.forEach((item, index) => {
						item.is_cert = this.dynamicValidateForm.domains[0].is_cert;
						if (index != 0) {
							item.is_cert_disabled = true;
						}
					})
			
			
					if (this.dynamicValidateForm.domains[0].is_cert == '是') {
						this.price = parseFloat(this.detailData.price) + parseFloat(this.detailData.cert_fee);
					} else {
						this.price = parseFloat(this.detailData.price);
					}
					this.total_fee = this.price * this.gift_count * 1.00;
					this.bindInvoiceData();
				}
			},
			addDomain() {
				var _package_items = '';
				var _package_items_title = '';
				var _package_items_disabled = false;
				var _job_type='';
				var _job_type_disabled = false;
				var _school='';
				var _college='';
				var _company='';
				var _is_cert = '';
				var _is_cert_disabled = false;
				var _is_show_more = true;
				if(this.detailData.case_type ==2 && _is_show_more){
					_is_show_more = false;
				}
				if (this.detailData.package_items && this.detailData.package_items.length > 0 && this.dynamicValidateForm
					.domains[0].package_items) {
					_package_items = this.dynamicValidateForm.domains[0].package_items;
					_package_items_title = this.detailData.package_items.filter(i => i.id == _package_items.toString())[0].title;
					_package_items_disabled = true;
					if(_package_items_title.indexOf('线上')>-1 && _is_show_more){
						_is_show_more = false;
					}
				}
				if(this.detailData.student_price && this.dynamicValidateForm.domains[0].job_type){
					_job_type = this.dynamicValidateForm.domains[0].job_type;
					_job_type_disabled = true;
				}
				if(this.dynamicValidateForm.domains[0].school){
					_school = this.dynamicValidateForm.domains[0].school;
				}
				if(this.dynamicValidateForm.domains[0].college){
					_college = this.dynamicValidateForm.domains[0].college;
				}
				if(this.dynamicValidateForm.domains[0].company){
					_company = this.dynamicValidateForm.domains[0].company;
				}
				if (this.dynamicValidateForm.domains[0].is_cert) {
					_is_cert = this.dynamicValidateForm.domains[0].is_cert;
					_is_cert_disabled = true;
				}
				this.dynamicValidateForm.domains.push({
					package_items: _package_items,
					package_items_title: _package_items_title,
					package_items_disabled: _package_items_disabled,
					is_show_more: _is_show_more,
					name: '',
					mobile: '',
					sex: '',
					job_type: _job_type,
					job_type_disabled: _job_type_disabled,
					school: _school,
					college: _college,
					title: '',
					duty: '',
					teaching_length: '',
					company: _company,
					education: '',
					diet_type: '非清真',
					check_in_date: '',
					check_in_date_other: '',
					stay_type: '',
					hotel: '',
					room_type_before: '',
					room_type: '',
					room_type_other: '',
					room_share_with: '',
					stay_days: '',
					is_visit: '',
					id_card: '',
					is_cert: _is_cert,
					is_cert_disabled: _is_cert_disabled,
					pca: '',
					province: '',
					city: '',
					area: '',
					address: '',
					key: Date.now()
				});
				this.gift_count++;
				this.total_fee = this.price * this.gift_count * 1.00;
				this.bindInvoiceData();
				this.isGiftAdd();
			},
			removeDomain(item) {
				var index = this.dynamicValidateForm.domains.indexOf(item)
				if (index !== -1) {
					this.dynamicValidateForm.domains.splice(index, 1)
					this.dynamicValidateForm2.domains.splice(index, 1)
					this.gift_count --;
					this.total_fee =this.price*this.gift_count*1.00;
					this.bindInvoiceData();
					this.isGiftAdd();
				}
			},
			infoConfirm(){
				var that = this;
				that.$refs['dynamicValidateForm'].validate((valid) => {
					if (valid) {
						that.dynamicValidateForm.domains.forEach((item,index)=>{
							if(item.pca && item.pca.length == 3){
								item.province = item.pca[0];
								item.city = item.pca[1];
								item.area = item.pca[2];
							}
						});
						
						if(this.checked){
							//包含发票
							that.$refs['dynamicValidateForm2'].validate((_valid) => {
								if (_valid) {
									console.log('可以提交')
									console.log(this.dynamicValidateForm.domains)
									console.log('dynamicValidateForm' + JSON.stringify(that.dynamicValidateForm.domains))
									console.log('dynamicValidateForm2' + JSON.stringify(that.dynamicValidateForm2.domains))
									that.search.id = this.id;
									that.search.gift_buy_count = this.gift_count;
									that.search.user_list_json = JSON.stringify(that.dynamicValidateForm.domains);
									that.search.invoice_list_json = JSON.stringify(that.dynamicValidateForm2.domains);
									that.goToOrder();
								}
							});
						}else{ 
							//不包含发票
							that.search.id = this.id;
							that.search.gift_buy_count = this.gift_count;
							that.search.user_list_json = JSON.stringify(that.dynamicValidateForm.domains);
							that.goToOrder();
						}
					}
				});
			},
			changePayMethod(index){
				var that = this;
				that.page_index = 2;
				that.detailData.tt_out_trade_no = that.orderData.out_trade_no;
				that.changePayTab(index);
				that.scrollTop();
			},
			goToOrder(){
				//console.log(JSON.stringify(this.search))
			
				var that = this;
				var url = apiUrl.tt_purchase_confirm;
				this.Ajax(url, this.search, (data) => {
					that.orderData = data;
					that.page_index = 2;
					that.stateData = {
						pay_method: that.orderData.pay_method,
						result: that.orderData.result,
						applyer: that.orderData.applyer,
						applyer_count:that.orderData.applyer_count,
						time:that.orderData.time,
						place:that.orderData.place,
						is_offline_pay:that.orderData.is_offline_pay,
					};
					that.scrollTop();
					console.log(JSON.stringify(data))
				});
				
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(formName == 'dynamicValidateForm'){
							this.alert_show1 = true;
						}else if(formName == 'dynamicValidateForm2'){
							this.alert_show2 = true;
						}
					} else {
						console.log('error submit!!');
						if(formName == 'dynamicValidateForm'){
							this.alert_show1 = false;
						}else if(formName == 'dynamicValidateForm2'){
							this.alert_show2 = false;
						}
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			changePayTab(index) {
				this.pay_tab_index = index;
				var that = this;
				that.$nextTick(() => {
					if(that.orderData && that.orderData.qrcode_url){
						that.setupQrCode(that.$refs.qrcode, that.orderData.qrcode_url, 230, (code_img) => {
							that.qrcode = code_img;
						});
					}
				});
			},
			updateOrderInfo(index){
				var that = this;
				var url = apiUrl.tt_order_info_update;
				this.Ajax(url, {
					out_trade_no: that.orderData.out_trade_no,
					pay_method: index,
					product_type:27,
				}, (data) => {
					//that.$alert('缴费方式确认完成');
					that.page_index = 2;
					that.checkOrderState();
				});
			},
			finishConfirm(){
				this.page_index=2;
				this.checkOrderState();
			},
			checkOrderState(){
				var that = this;
				var url = apiUrl.tt_order_state_check;
				this.Ajax(url, { 
					out_trade_no: this.orderData.out_trade_no,
					product_type: 27,
				}, (data) => {
					that.stateData = data;
					that.scrollTop();
				});
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_conference_detail_get;
				this.Ajax(url, {
					id: that.id,
					is_sign_up: 1,
				}, (data) => {
					that.detailData = data;
					document.title = "报名 - "+ data.title + ' - 机工教育会议';
					if (that.detailData.is_package != 1) {
						that.price = parseFloat(that.detailData.price);
					} else {
						/*
						var item = that.detailData.package_items[that.detailData.package_items.length-1];
						that.dynamicValidateForm.domains[0].package_items = item.id;
						that.price = parseFloat(item.price);
						*/
					}
					
					if(that.detailData.case_type == 2){
						that.dynamicValidateForm.domains[0].is_show_more = false;
					}
					
					if(that.detailData.is_reg == 0){
						that.page_index = 1;
						that.gift_count = 1;
						that.total_fee =that.price*that.gift_count*1.00;						
						that.bindInvoiceData();

					}else if(that.detailData.order_info){
						that.page_index = 2;
						
						that.stateData = {
							pay_method: that.detailData.order_info.pay_method,
							result: that.detailData.order_info.result,
							applyer: that.detailData.order_info.applyer,
							applyer_count:that.detailData.order_info.applyer_count,
							time:that.detailData.order_info.time,
							place:that.detailData.order_info.place,
							is_offline_pay:that.detailData.order_info.is_offline_pay,
						};
						
						that.orderData = {
							total_fee : that.detailData.order_info.total_fee,
							out_trade_no: that.detailData.order_info.out_trade_no,
							product_id: that.detailData.order_info.product_id,
							product_type: that.detailData.order_info.product_type,
							remark: that.detailData.order_info.remark,
							qrcode_url: that.detailData.order_info.qrcode_url,
							media_list: that.detailData.order_info.media_list,
						};
					}
				});
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},
			getInvoiceTitleList() {
				var that = this;
				var url = apiUrl.tt_invoice_title_list_get;
				this.Ajax(url, {}, (data) => {
					that.invoice_title_list = data;
				});
			},
			querySearch(queryString, cb) {
				var restaurants = this.invoice_title_list;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				  return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			handleSelect(item) {
				console.log(item);
				if(item){
					this.dynamicValidateForm2.domains[0].tax_id = item.address;	
				}
			},
			handleExceed(files, uploadFiles)  {
				this.$message.error("最多上传3个图片");
			},
			handlChange(file, fileList) {
				this.fileList = fileList;
				console.log(this.fileList);
				if(fileList.length!=0){
					this.showAlert = false;
				}else{
					this.showAlert = true;
				}
			},
			handleRemove(file, fileList)  {
				this.fileList = fileList;
			   console.log(file, fileList)
			},
			beforeUpload(file) {
				const MAX_SIZE = 1024 * 1024 * 5; // 5MB
				if (file.size > MAX_SIZE) {
					this.$message.error('每个文件大小不能超过5MB!');
					return false;
				}
				return true;
			},
			closeAlert(){
				this.showAlert = false;
			},
			loadPreviewSrcList(){
				if(this.orderData.media_list){
					var arr =new Array();
					this.orderData.media_list.forEach((item,index)=>{
						arr.push(item.img_url);
					})
					return arr;
				}else{
					return [];
				}
			},
			uploadConfirmClick(){
				var that = this;
				if(this.fileList.length == 0){
					this.showAlert = true;
					return;
				}else{
					this.showAlert = false;
				}
				console.log(JSON.stringify(this.fileList))
					
				var url = apiUrl.tt_camp_order_media_upload;
				var formData = new FormData();
				this.fileList.forEach((file,index) => {
					formData.append("file"+index, file.raw);
				});
				var token = "";
				var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
				if (userInfo && userInfo.token != '') {
					token = userInfo.token;
				}
				formData.append("token", token);
				formData.append("client_type", 10);
				formData.append("out_trade_no", that.orderData.out_trade_no);
				that.Ajax(url, formData, (data) => {
					that.$message.success("上传成功");
					that.orderData.media_list = data;
					that.fileList = [];
				});
				
			}
			
			
		},
	};
</script>

<style>
	
	/*单选按钮*/
	/* .el-radio__input.is-checked + .el-radio__label {
	  color: #606266 !important;
	}

	.el-radio__input.is-checked .el-radio__inner {
	  background: #606266 !important;
	  border-color: #606266 !important;
	}
	.el-radio__input:hover{
		border-color: #DCDFE6 !important;
	} */
	
</style>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.pay_tabs {
			display: flex;
			margin-top: 20px;

			.pay_tab {
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 315px;
				height: 90px;
				background-color: #F1F1F1;
				border-radius: 10px;
				position: relative;
				box-sizing: border-box;
				cursor: pointer;
				margin-right: 30px;

				&.actived {
					background-color: #fff;
					border: 2px solid #ff9000;


				}

				.tag {
					background-color: #ff9000;
					width: 30px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 10px 0 10px 0;
					position: absolute;
					right: -2px;
					bottom: -2px;
					box-sizing: border-box;
				}
			}
		}
		
		/deep/.sign_up_container{
			/*复选按钮*/
			.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
			    background-color: #FCA459 !important;
			    border-color: #FCA459 !important;
			}
			
			.el-checkbox__input.is-focus .el-checkbox__inner{
				border-color: #FCA459 !important;
			}
			.el-checkbox__label{
				font-size: 15px;
				color: #333 !important;
			}
			.el-checkbox__input.is-checked+.el-checkbox__label{
				color: #FCA459 !important;
			}
			
			.el-radio{
				line-height: 2 !important;
			}
			
			.el-collapse{
				border: none !important;
			}
			.el-collapse-item__wrap{
				border: none !important;
			}
			.el-collapse-item__header{
				padding-left: 156px;
				box-sizing: border-box;
				border: none !important;
			}
			
		}
		
		/*
		.sign_up_container {
			/deep/ .el-radio__label {
				font-size: 18px !important;
			}

			/deep/ .el-radio__inner {
				border-radius: 4px !important;
				width: 20px !important;
				height: 20px !important;
				background-color: #fff !important;
			}

			/deep/ .el-radio__inner::after {
				width: 12px !important;
				height: 12px !important;
				border-radius: 4px !important;
				background-color: #999 !important;
			}
			
			
			/deep/ .el-radio__input.is-checked+.el-radio__label {
				color: rgb(153, 153, 153) !important;
			}

			/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
				color: #666 !important;
			}

			/deep/ .el-checkbox__label {
				font-size: 14px;
			}

			/deep/ .el-checkbox__inner {
				border-radius: 4px !important;
				width: 20px !important;
				height: 20px !important;
				background-color: #fff !important;
			}

			/deep/ .el-checkbox__inner::after {
				width: 12px !important;
				height: 12px !important;
				border-radius: 4px !important;
				background-color: #999 !important;
				box-sizing: content-box;
			}
		}
		*/
	}
</style>