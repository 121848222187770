<template>
	<div class="page-main">
		<div class="body-main">
			<div class="tag_box_container">
				<div class="tag_box_inner" v-if="categoryData">
					<div class="tag_box border-bottom">
						<div class="tag_title">
							教育层次
						</div>
						<div class="tag_list">
							<div @click="type1ChangeClick(item)" v-for="(item,index) in findCategoryList(1)"
								class="tag tag1 margin-bottom" :class="{'disabled': item.product_count == 0,'actived':type1 && item.product_count>0 && item.id == type1.id}">{{item.title}}
							</div>
						</div>
					</div>

					<div class="tag_box border-bottom">
						<div class="tag_title">
							课程方向
						</div>
						<div class="tag_list">
							<div @click="type2ChangeClick(item)" v-for="(item,index) in findCategoryList(2)"
								class="tag tag1 margin-bottom" :class="{'disabled': item.product_count == 0,'actived':type2 && item.product_count>0 && item.id == type2.id}">{{item.title}}
							</div>
						</div>
					</div>

					<div class="tag_box">
						<div class="tag_title">
							专业方向
						</div>
						<div class="tag_list">
							<div @click="type3ChangeClick(item)" v-for="(item,index) in findCategoryList(3)"
								class="tag tag1 margin-bottom" :class="{'disabled': item.product_count == 0,'actived':type3 && item.product_count>0 &&item.id == type3.id}">{{item.title}}
							</div>
						</div>
					</div>

					<!-- <div class="tag_box" style="margin-top: -20px; margin-bottom: 10px;">
						<div class="tag_title"></div>
						<div class="tag_list bg">
							<div class="tag-tit">热门标签</div>
							<div v-for="(item,index) in tagData" class="tag tag2" :class="{'actived':index == 0}">{{item}}</div>
						</div>
					</div> -->
				</div>
			</div>

			<div
				style="display: flex;flex-direction: column; align-items: center; width: 100%; box-sizing: border-box; overflow: hidden; position: relative;">
				<img src="../../assets/images/bg_course_list_01.png"
					style="width: 100%;position: absolute; top: 0; z-index: 2;" />
				<div
					style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box;z-index: 3;">
					<!-- <div class="mianb">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item :to="{ path: '/' }">视频课</el-breadcrumb-item>
							<el-breadcrumb-item :to="{ path: '/' }">课程列表</el-breadcrumb-item>
						</el-breadcrumb>
					</div> -->

					<div style="display: flex; margin: 30px 0;">
						<div class=""
							style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">

						</div>
						<div style="display: flex;flex-direction: column; line-height: 1;">
							<div style="font-size: 28px; color: #333;">课程</div>
							<div style="font-size: 17px; color: #999; margin-top: 10px;">如何有效用好一本书，教好一门课</div>
						</div>
					</div>

					<div class="course_list" style="margin-top: 20px;" v-if="productData && productData.list">
						<div class="item"  style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;"
							 v-for="(item,index) in productData.list"
							@click="toDetail(item)">
							<img style="width: 100%;" v-lazy="course_img_url" v-if="item.img_url_tt==''" />
							<img style="width: 100%;" v-lazy="item.img_url_tt" v-if="item.img_url_tt!=''" />
							<div class=""
								style="flex: 1; background-color: #F1F1F1; display: flex;flex-direction: column;">
								<div
									style="flex: 1; display: flex;flex-direction: column; padding: 15px 10px 10px 15px;">
									<div style="font-size: 20px; font-weight: 600; line-height: 1.2; ">{{item.title}}
									</div>
									<div style="font-size: 18px;line-height: 1; margin-top: 8px;">主讲人：{{item.author}}
									</div>
								</div>
								<div
									style="height: 40px;  border-radius: 0 0 20px 20px; border-top: 1px solid #E8E8E8; display: flex; justify-content: space-between; align-items: center; padding:0 17px 0 20px;">
									<div style="display: flex;font-size: 13px; align-items: center;">
										<div
											style="background-color: #c93a2e;color: #fff; padding:2px 8px; border-radius:20px;">
											{{item.res_count}}小节</div>
										<div style=" margin-left: 8px;"><i class="el-icon-pie-chart"
												style="margin-right: 2px;"></i>{{item.res_length}}</div>
									</div>
									
									<div v-if="item.is_office_product != 1">
										<div style="font-weight: 600; font-size: 17px; color: #c93a2e; display: flex; line-height: 1; align-items: center;" v-if="item.price!='0.00' && item.line_price!=''">
											<div style=" font-size: 10px; width: 10px; margin-right: 5px;">特惠价</div>
											<div style="display: flex;flex-direction: column;">
												<div style="font-weight: 600; font-size: 17px; color: #c93a2e;">¥{{item.price}}</div>
												<div style="font-weight: 600; font-size: 10px; color: #999;text-decoration: line-through;">¥{{item.line_price}}</div>
											</div>
										</div>
										
										<div style="display: flex; align-items: center;font-weight: 600; font-size: 17px; color: #c93a2e;line-height: 1;" v-if="item.price!='0.00' && item.line_price==''">
											¥{{item.price}}
										</div>
										
										<div style="display: flex; align-items: center;" v-if="item.price=='0.00' && item.line_price!=''">
											<div style="font-weight: 600;font-size: 16px; color: #999; margin-right: 5px; text-decoration: line-through;">
												¥{{item.line_price}}
											</div>
											<div style="font-weight: 600; font-size: 17px; color: #c93a2e;">限免</div>
										</div>
										
										<div style="display: flex; align-items: center;font-weight: 600; font-size: 17px; color: #c93a2e;line-height: 1;" v-if="item.price=='0.00' && item.line_price==''">
											免费
										</div>
									</div>
									
								</div>
							</div>


							<!-- <img class="cover" v-lazy="item.img_url_compressed" />
							<div class="mes">
								<div class="title">{{item.title}}</div>
								<div class="anchor">{{item.author}}</div>
								<div class="res_info">
									<div class="res_count">{{item.res_count}}小节</div>
									<div class="res_length"><i class="el-icon-pie-chart"
											style="margin-right: 2px;"></i>{{item.res_length}}</div>
								</div>
								<div class="exts">
									<div class="price">免费</div>
									<div class="readers">{{item.readers_count}}人正在阅读</div>
								</div>
								<img class="icon_play" src="../../assets/images/icon_play_light.png" />
							</div> -->
						</div>
						
				
						
						<div v-if="!productData.list" style="width: 100%; height: 480px; display: flex; flex-direction: column;justify-content: center;align-items: center;">
							<img style="width: 230px;" src="../../assets/images/empty.png" />
							<div style="font-size: 17px; margin-top: 20px;">抱歉，未找到您需要的内容</div>
						</div>

						<div class="book_pager" v-if="productData.total != 0">
							<el-pagination @current-change="currentPage" :current-page.sync="search.page_index"
								:page-size="search.page_size" :pager-count="5" layout="prev, pager, next"
								:total="productData.total">
							</el-pagination>
							<div class="page_count">
								共 {{productData.count}} 页
							</div>
						</div>
						
						
					</div>


				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 8
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				categoryData: null,
				typeData: ["机械", "电子电工", "电力", "电气", "数学", "物理", "化学", "机械", "电子电工", "电力", "电气", "数学", "物理", "化学", "机械",
					"电子电工", "电力", "电气", "数学", "物理", "化学"
				],
				priceData: ["全部", "免费", "0-49元", "50-99元", "100-199元", "200-499元", "500-999元", "1000 元以上"],
				tagData: ["全部", "教学改革", "101计划", "教学改革", "101计划", "教学改革", "101计划"],
				tab_index: 1,
				type1: null,
				type2: null,
				type3: null,
				course_img_url:require("../../assets/images/cover.png"),
			};
		},
		created() {
			document.title = "课程 - 机工教师特训营";
			this.getCategoryList();
			this.getProductList();
		},
		methods: {
			toDetail(item){
				this.GoToDetail(item.product_type,item.id);
			},
			type1ChangeClick(item) {
				if(item.product_count == 0){
					return;
				}
				this.type1 = item;
				this.searchClick();
			},
			type2ChangeClick(item) {
				if(item.product_count == 0){
					return;
				}
				this.type2 = item;
				this.searchClick();
			},
			type3ChangeClick(item) {
				if(item.product_count == 0){
					return;
				}
				this.type3 = item;
				this.searchClick();
			},
			searchClick() {
				if (this.type1) {
					if(this.type1.id == 0){
						this.search.type1 = '';	
					}else{
					  	this.search.type1 = this.type1.id;	
					}
				}
				if (this.type2) {
					if(this.type2.id == 0){
						this.search.type2 = '';	
					}else{
					  	this.search.type2 = this.type2.id;	
					}
				}
				if (this.type3) {
					if(this.type3.id == 0){
						this.search.type3 = '';	
					}else{
					  	this.search.type3 = this.type3.id;	
					}
				}
				this.productData.total = 0;
				this.productData.count = 0;
				this.productData.list = null;
				this.currentPage(1);
			},
			tabChangeClick(index) {
				this.tab_index = index;
			},
			findCategoryList(type) {

				var list = this.categoryData.filter(el => el.type == type);
				list.unshift({
					id: 0,
					title: '全部',
					type: 0,
					product_count: 1000
				})
				return list;
			},
			getCategoryList() {
				var that = this;
				var url = apiUrl.tt_category_list_get;
				this.Ajax(url, { product_type :4}, (data) => {
					that.categoryData = data;
				});
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_course_list_get;
				this.Ajax(url, this.search, (data) => {
					if(data){
						if (that.productData.total == 0) {
							that.productData.total = data.total;
						}
						if (that.productData.count == 0) {
							that.productData.count = data.count;
						}
						that.productData.list = data.list;
						//console.log('productData' + JSON.stringify(that.productData))
					}else{
						that.productData.total = 0;
						that.productData.count = 0;
						that.productData.list = null;
					}
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			}
		},
	};
</script>



<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.book_pager {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 40px;

			.page_count {
				display: inline-flex;
				height: 40px;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				color: #959595;
			}

			.el-pagination {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.el-pagination .el-icon {
				font-size: 22px !important;
				cursor: pointer;
			}


			.el-pager li {
				font-size: 16px !important;
				display: inline-flex;
				width: 40px !important;
				min-width: 40px !important;
				height: 40px !important;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				border-radius: 50%;
				font-weight: 400;
				color: #959595;
				margin: 5px;
				cursor: pointer;
			}



			.el-pager li.active {
				background-color: #F3641E !important;
				color: #fff !important;

			}

		}

		.body-main {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #fff;
			//padding: 10px 0;
			box-sizing: border-box;

			.tag_box_container {
				width: 100%;
				display: flex;
				background-color: #FAFAFA;
				justify-content: center;
				align-items: center;

				.tag_box_inner {
					width: 1200px;
					background-color: #FAFAFA;
					display: flex;
					flex-direction: column;
					padding: 10px 0;

					.tag_box {
						display: flex;
						width: 100%;
						padding: 20px 0 10px 0;

						&.border-bottom {
							border-bottom: 1px dashed #ddd;
						}

						.tag_title {
							font-size: 16px;
							color: #333;
							margin-right: 30px;
							width: 65px;
						}

						.tag_list {
							flex: 1;
							display: flex;
							flex-wrap: wrap;

							&.bg {
								background-color: #F1F1F1;
								padding: 10px 20px;
								border-radius: 10px;
							}

							.tag-tit {
								font-size: 14px;
								color: #333;
								padding: 4px 10px;
							}

							.tag {
								font-size: 14px;
								color: #333;
								padding: 4px 10px;
								cursor: pointer;
								margin-right: 18px;

								&.margin-bottom {
									margin-bottom: 10px;
								}
							}
							
							.disabled{
								color: rgba(0, 0, 0, 0.3) !important;
							}

							.tag1.actived,
							.tag1:not(.disabled):hover {
								background-color: #E5E5E5;
								color: #CA3D25;
								border-radius: 5px;
							}

							.tag2.actived,
							.tag2:not(.disabled):hover {
								color: #CA3D25;
							}
						}
					}
				}
			}


			.mianb {
				width: 100%;
				height: 75px;
				margin: 0 auto;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 18px;
				font-family: PingFang-SC-Medium, PingFang-SC;
				font-weight: 500;
				color: #363636;


				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__inner a,
				.el-breadcrumb__inner.is-link,
				.el-breadcrumb__separator {
					color: #00786c !important;
				}

				.el-breadcrumb__inner.is-link:hover {
					border-bottom: 1px solid #00786c;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #00786c !important;
				}
			}

			.tabs {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 28px;
				margin: 40px 0 30px 0;

				.tab {
					color: #999;
					cursor: pointer;

					&.active {
						color: #01786D;
						font-weight: 600;
					}
				}

				.tab_split {
					display: inline-block;
					margin: 0 8px;
					color: #01786D;
					font-weight: 600;
					font-size: 32px;
				}
			}

			.course_list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				.item {
					width: 280px;
					height: 376px;
					//height: 325px;
					//border: 1px solid #eee;
					display: flex;
					flex-direction: column;
					//box-shadow: 5px 5px 10px #eee;
					//box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
					margin-right: 20px;
					margin-bottom: 40px;
					cursor: pointer;
					overflow: hidden;

					&:nth-child(4n+4) {
						margin-right: 0;
					}

					&:hover {
						//box-shadow:0 1px 1px 1px 10px #666;
						//box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.1);
					}

					.cover {
						width: 285px;
						height: 220px;
						//height: 165px;
					}


					.mes {
						width: 100%;
						display: flex;
						flex-direction: column;
						line-height: 1;
						padding-left: 18px;
						box-sizing: border-box;
						margin: 0;
						position: relative;

						.title {
							margin-top: 15px;
							height: 20px;
							margin-left: -18px;
							font-size: 20px;
							color: #333;
							font-weight: 600;
							border-left: 8px solid #333;
							padding-left: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}


						.anchor {
							font-size: 17px;
							margin-top: 10px;
							color: #333;
							display: flex;
						}

						.res_info {
							display: flex;
							justify-content: flex-start;
							font-size: 12px;
							margin-top: 15px;
							position: relative;

							.res_count {
								background-color: #333;
								color: #fff;
								padding: 5px 8px;
								display: inline-flex;
								border-radius: 4px 0 0 4px;
							}

							.res_length {
								background-color: #3CD0BA;
								color: #01786D;
								padding: 5px 8px;
								display: inline-flex;
								border-radius: 0 4px 4px 0;
							}
						}

						.exts {
							display: flex;
							align-items: flex-end;
							margin-top: 25px;

							.price {
								font-size: 21px;
								color: #333;
								margin-right: 8px;
								display: inline-flex;
							}

							.readers {
								font-size: 12px;
								color: #3CD0BA;
								display: inline-flex;
							}
						}

						.icon_play {
							width: 150px;
							height: 150px;
							margin: 0;
							position: absolute;
							z-index: 2;
							right: -24px;
							bottom: -24px;
						}
					}
				}
			}
		}
	}
</style>